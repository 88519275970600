import { computed } from '@vue/composition-api'
import { formatInTimeZone } from 'date-fns-tz'
import { format } from 'date-fns'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'

const { getTokenService } = getCompanySettingsFromToken()

export default function dateTimeFormats() {
  const getTimeZone = computed(() => {
    return getTokenService.value?.timeZone
  })
  const getDateTimeFormat = computed(() => {
    return getTokenService.value?.dateTimeFormatName
  })

  const dateOnly = computed(() => {
    return getDateTimeFormat.value.slice(0, 10)
  })
  const twoDigits = num => {
    return (num < 10 ? '0' : '') + num
  }
  const secondsToTime = time => {
    const hours = Math.floor(time / 3600)
    time = time - hours * 3600
    const minutes = Math.floor(time / 60)
    const seconds = time - minutes * 60

    return `${twoDigits(hours)}:${twoDigits(minutes)}:${twoDigits(seconds)}`
  }
  const hoursAndMinutes = time => {
    const hours = Math.floor(time / 3600)
    time = time - hours * 3600
    const minutes = Math.floor(time / 60)

    return `${twoDigits(hours)}:${twoDigits(minutes)}`
  }
  const calcTime = (val, offset) => {
    let hours = Math.trunc(Number(offset))
    let minutes = (Number(offset) - hours) * 100
    let minutesOffset = Math.trunc(hours * 60 + minutes)
    let date = new Date(val)
    let utc = date.getTime() + date.getTimezoneOffset() * 60000
    let newDate = new Date(utc + 60000 * minutesOffset)
    return newDate
  }
  const dateFormatInTimeZone = (
    val,
    format = getDateTimeFormat?.value
      ?.replaceAll('Y', 'y')
      .replaceAll('D', 'd')
      .replaceAll('A', 'aaa')
  ) => {
    if (val) {
      const date = new Date(val)
      if (date && getTimeZone?.value && format) {
        return formatInTimeZone(date, getTimeZone?.value, format)
      }
    }
  }
  const dateFormat = (val = new Date(), patternStr = 'dd/MM/yyyy HH:mm:ss') => {
    const date = new Date(val)
    return format(
      date,
      patternStr
        .replaceAll('Y', 'y')
        .replaceAll('D', 'd')
        .replaceAll('A', 'aaa')
    )
  }
  const dateTime = val => {
    return dateFormat(val, getDateTimeFormat.value)
  }
  const hours = (val, showSeconds) => {
    let date = new Date(val)
    let hour = twoDigits(date.getHours())
    let minutes = twoDigits(date.getMinutes())
    let seconds = twoDigits(date.getSeconds())
    return showSeconds ? `${hour}:${minutes}:${seconds}` : `${hour}:${minutes}`
  }
  const secondsToTimeFormatHMS = seconds => {
    const days = Math.floor(seconds / (24 * 60 * 60))
    const remainingSeconds = seconds % (24 * 60 * 60)
    const hours = Math.floor(remainingSeconds / (60 * 60))
    const remainingSecondsAfterHours = remainingSeconds % (60 * 60)
    const minutes = Math.floor(remainingSecondsAfterHours / 60)
    const remainingSecondsFinal = remainingSecondsAfterHours % 60
    const formattedTime = []

    if (days > 0) {
      formattedTime.push(`${days} day${days > 1 ? 's' : ''}`)
    }
    if (hours > 0) {
      formattedTime.push(`${hours}h`)
    }
    if (minutes > 0) {
      formattedTime.push(`${minutes}min`)
    }
    if (remainingSecondsFinal > 0) {
      formattedTime.push(`${remainingSecondsFinal.toFixed(0)}s`)
    }

    return formattedTime.join(' ')
  }
  const convertMsToTime = milliseconds => {
    let seconds = Math.floor(milliseconds / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    let days = Math.floor(hours / 24)

    seconds = seconds % 60
    minutes = minutes % 60
    hours = hours % 24

    let timeString = `${twoDigits(days)}d ${twoDigits(hours)}h ${twoDigits(
      minutes
    )}m ${twoDigits(seconds)}s`

    if (twoDigits(days) === '00') {
      timeString = timeString.slice(4)
    }
    if (twoDigits(hours) === '00') {
      timeString = timeString.slice(4)
    }
    if (twoDigits(minutes) === '00') {
      timeString = timeString.slice(4)
    }
    return timeString
  }
  const durationDateTime = (dateStart, dateEnd) => {
    let start = new Date(dateStart)
    let end = new Date(dateEnd)
    let sum = Math.abs(new Date(end) - new Date(start))
    return convertMsToTime(sum)
  }

  return {
    twoDigits,
    calcTime,
    dateTime,
    hours,
    dateFormat,
    dateOnly,
    getDateTimeFormat,
    dateFormatInTimeZone,
    getTimeZone,
    secondsToTime,
    secondsToTimeFormatHMS,
    convertMsToTime,
    durationDateTime,
    hoursAndMinutes
  }
}
