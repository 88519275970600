import axios from 'axios'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
import { errorInterceptor } from '@/backend/axiosInterceptors'
import { TokenService } from '@/services/storage.service'

const { getToken, getChildCompanySettings } = getCompanySettingsFromToken()

const IntegrationBackend = {
  client: axios.create({
    baseURL: process.env.VUE_APP_INTEGRATION_API,
    timeout: 0
  }),

  init() {
    this.client.interceptors.response.use(
      response => response,
      error => errorInterceptor(error)
    )
    this.client.defaults.headers['Authorization'] = `Bearer ${getToken.value}`
  },
  setHeader() {
    this.client.defaults.headers['Authorization'] = `Bearer ${getToken.value}`
  },
  setChildCompanyAccessToken() {
    this.client.defaults.headers.Authorization = `Bearer ${getChildCompanySettings.value.token}`
  },
  setSupportAccessToken() {
    this.client.defaults.headers[
      'Authorization'
    ] = `Bearer ${TokenService.getCurrentChildCompany.value?.token}`
  },
  get(resource) {
    return this.client.get(resource)
  },

  post(resource, data) {
    return this.client.post(resource, data)
  },

  put(resource, data) {
    return this.client.put(resource, data)
  },

  delete(resource) {
    return this.client.delete(resource)
  }
}

export default IntegrationBackend

IntegrationBackend.init()
