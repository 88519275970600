<template>
  <div
    class="d-flex flex-column"
    style="background-color: white; overflow-y: auto"
  >
    <v-menu
      v-if="dispatch"
      content-class="translationsMenu"
      offset-x
      transition="scale-transition"
    >
      <template #activator="{ on }">
        <v-list-item
          v-if="isDispatchEnabled"
          class="d-flex justify-space-between px-10"
          v-on="on"
        >
          <v-list-item-title class="font-weight-medium text-caption">{{
            $t('global.WayBills')
          }}</v-list-item-title>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item>
      </template>
      <v-list-item
        class="ma-2"
        :to="{
          name: 'WayBills',
          query: {
            ...routeQuery,
            from: new Date(initialDateRange.start).toISOString(),
            to: new Date(initialDateRange.end).toISOString()
          }
        }"
      >
        <v-list-item-title class="font-weight-medium text-caption">
          Front Loader
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        class="ma-2"
        :to="{
          name: 'JobsRolloff',
          query: {
            ...routeQuery
          }
        }"
      >
        <v-list-item-title class="font-weight-medium text-caption">
          Roll off
        </v-list-item-title>
      </v-list-item>
    </v-menu>
    <v-list v-if="dispatchMiniVariant">
      <v-list-group v-if="isDispatchEnabled" class="translations">
        <template #activator>
          <v-list-item class="px-10">
            <v-list-item-title class="font-weight-medium text-caption">{{
              $t('global.WayBills')
            }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item-group v-model="jobsMenu">
          <v-list-item
            class="ml-5"
            :to="{
              name: 'WayBills',
              query: {
                ...routeQuery,
                from: new Date(initialDateRange.start).toISOString(),
                to: new Date(initialDateRange.end).toISOString()
              }
            }"
          >
            <v-list-item-title class="font-weight-medium text-caption">
              Front Loader
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            class="ml-5"
            :to="{
              name: 'JobsRolloff',
              query: {
                ...routeQuery
              }
            }"
          >
            <v-list-item-title class="font-weight-medium text-caption">
              Roll off
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list-group>
    </v-list>
    <v-list v-if="translations">
      <v-list-item class="px-10" :to="{ name: 'userProfile' }">
        <v-list-item-icon class="mr-2">
          <v-icon> mdi-account </v-icon>
        </v-list-item-icon>
        <v-list-item-title class="font-weight-medium text-caption">{{
          $t('userProfile.UserProfile')
        }}</v-list-item-title>
      </v-list-item>
      <v-list-group v-if="!miniVariant" class="translations">
        <template #activator>
          <v-list-item class="px-10">
            <v-list-item-title class="font-weight-medium text-caption">{{
              $t('global.Language')
            }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item-group v-model="language">
          <v-list-item
            v-for="(item, i) in translationsList"
            :key="i"
            class="ml-5"
            @click="onClick(item.value)"
          >
            <v-list-item-title class="font-weight-medium text-caption">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list-group>
      <v-menu
        v-else
        content-class="translationsMenu"
        offset-x
        transition="scale-transition"
      >
        <template #activator="{ on }">
          <v-list-item class="d-flex justify-space-between px-10" v-on="on">
            <v-list-item-title class="font-weight-medium text-caption">{{
              $t('global.Language')
            }}</v-list-item-title>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item>
        </template>
        <v-list-item
          v-for="(item, i) in translationsList"
          :key="i"
          class="ma-2"
          @click="onClick(item.value)"
        >
          <v-list-item-title class="font-weight-medium text-caption">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-menu>
      <v-list-item
        v-if="translations && supportingUser"
        class="px-10 font-weight-medium text-caption"
        @click="logOutAsCustomer"
      >
        <v-list-item-title class="font-weight-medium text-caption">
          {{ `${$t('menu.SignOutAs')} ${supportingUser.userName}` }}
        </v-list-item-title>
        <v-list-item-icon> <v-icon>logout</v-icon> </v-list-item-icon>
      </v-list-item>
      <v-list-item
        v-if="user && !supportingUser"
        class="font-weight-medium text-caption px-10"
        @click="logOut"
      >
        <v-list-item-title class="font-weight-medium text-caption">
          {{ logout.name }}
        </v-list-item-title>
        <v-list-item-icon> <v-icon>logout</v-icon> </v-list-item-icon>
      </v-list-item>
    </v-list>
    <template v-for="(item, i) in menuItems" v-else>
      <v-list-item
        v-if="item.enabled"
        :key="i"
        class="px-10 font-weight-medium text-caption d-flex justify-space-between"
        exact
        :to="
          !item.jobsDropdown && !item.jobsDropdownTable && !item.dropdown
            ? item.to
            : ''
        "
      >
        {{ item.name }}
        <v-menu
          v-if="item.jobsDropdown"
          :key="i"
          offset-x
          transition="scale-transition"
        >
          <template #activator="{ on }">
            <v-icon v-on="on">mdi-chevron-right</v-icon>
          </template>
          <v-list>
            <v-list-item
              exact
              :to="item.to"
              class="px-10 font-weight-medium text-caption"
              >Front Loader</v-list-item
            >
            <v-list-item
              exact
              :to="'/Jobs/AddWasteJob'"
              class="px-10 font-weight-medium text-caption"
              >Roll off</v-list-item
            >
          </v-list>
        </v-menu>
        <v-menu
          v-if="item.dropdown"
          :key="i"
          offset-x
          transition="scale-transition"
          z-index="99999"
        >
          <template #activator="{ on }">
            <v-icon v-on="on">mdi-chevron-right</v-icon>
          </template>
          <v-list>
            <v-list-item
              exact
              :to="{
                name: item.to.name,
                query: {
                  ...item.to.query,
                  vehicle: true,
                  frontLoader: undefined,
                  rollOff: undefined
                }
              }"
              class="px-10 font-weight-medium text-caption"
              >Vehicle</v-list-item
            >
            <v-list-item
              exact
              :to="{
                name: item.to.name,
                query: {
                  ...item.to.query,
                  frontLoader: true,
                  vehicle: undefined,
                  rollOff: undefined
                }
              }"
              class="px-10 font-weight-medium text-caption"
              >Front Loader</v-list-item
            >
            <v-list-item
              exact
              :to="{
                name: item.to.name,
                query: {
                  ...item.to.query,
                  frontLoader: undefined,
                  vehicle: undefined,
                  rollOff: true
                }
              }"
              class="px-10 font-weight-medium text-caption"
              >Roll off</v-list-item
            >
          </v-list>
        </v-menu>
      </v-list-item>
      <v-divider v-if="item.divider" :key="i"></v-divider>
    </template>
    <v-list-item
      v-if="supportPannel"
      class="px-10 font-weight-medium text-caption"
      :to="pinLoginRoute"
    >
      {{ $t('supportPannel.PinLogin') }}
    </v-list-item>
  </div>
</template>
<script>
import { TokenService } from '@/services/storage.service'
import useAssets from '@/components/Livetracking/use/useAssets'
import supportingUserData from '@/components/SupportPannel/use/supportingUser'
import ApiBackend from '@/backend/apiBackend'
import HosBackend from '@/backend/hosBackend'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
import tablePaging from '@/components/tablePaging'
import startAndEndDateTime from '@/components/use/startAndEndDateTime'

export default {
  name: 'Dropdown',
  props: {
    menuItems: {
      type: Array,
      default: () => []
    },
    translations: {
      type: Boolean,
      default: false
    },
    supportPannel: {
      type: Boolean,
      default: false
    },
    miniVariant: {
      type: Boolean,
      default: false
    },
    dispatch: {
      type: Boolean,
      default: false
    },
    dispatchMiniVariant: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { initAssets, removeAllAssets } = useAssets()
    const { supportingUser, updateSupportingUsernameAndRoles } =
      supportingUserData()
    const {
      getUsername,
      getLanguage,
      getCompanyFeatures,
      getChildCompanyFeatures
    } = getCompanySettingsFromToken()
    const { routeQuery } = tablePaging()
    const { initialDateRange } = startAndEndDateTime()

    return {
      initAssets,
      removeAllAssets,
      supportingUser,
      updateSupportingUsernameAndRoles,
      getUsername,
      getLanguage,
      routeQuery,
      initialDateRange,
      getChildCompanyFeatures,
      getCompanyFeatures
    }
  },
  data() {
    return {
      language: null,
      user: null,
      jobsMenu: true
    }
  },
  computed: {
    translationsList() {
      return [
        { name: this.$t('menu.English'), value: 'en' },
        { name: this.$t('menu.Macedonian'), value: 'mk' },
        { name: this.$t('menu.Serbian'), value: 'rs' }
      ]
    },
    logout() {
      return {
        name: this.$t('menu.LogOut')
      }
    },
    pinLoginRoute() {
      return '/SupportPannel/SupportPinLogin/'
    },
    isDispatchEnabled() {
      return this.getChildCompanyFeatures
        ? this.getChildCompanyFeatures?.wasteManagement
        : this.getCompanyFeatures?.wasteManagement
    }
  },
  created() {
    this.user = this.getUsername || ''
    this.language = this.translationsList.findIndex(
      x => x.value === (this.getLanguage || this.$i18n.locale)
    )
  },
  methods: {
    changeLanguage(lang) {
      if (this.getLanguage !== lang) {
        TokenService.setLanguage(lang)
        this.$i18n.locale = lang
      }
    },
    logOut() {
      TokenService.removeToken()
      TokenService.removeSupportToken()
      TokenService.removeSupportTokenDetails()
      TokenService.removeUserId()
      TokenService.removeCompanySettings()
      TokenService.removeCurrentChildCompany()
      TokenService.removeUsername()
      TokenService.removeUserRole()
      TokenService.removeCorrelationId()
      TokenService.removeCompanyFeatures()
      TokenService.removeChildCompanyFeatures()
      TokenService.removeLanguage()
      TokenService.removeMonogotoToken()
      this.updateSupportingUsernameAndRoles(null)
      this.removeAllAssets()
      this.$router.push('/')
    },
    logOutAsCustomer() {
      this.removeAllAssets()
      TokenService.removeSupportToken()
      TokenService.removeSupportTokenDetails()
      ApiBackend.setHeader()
      HosBackend.setHeader()
      this.initAssets()
      this.updateSupportingUsernameAndRoles(null)
      this.$router.push('/')
    },
    onClick(language) {
      if (this.translations && language) {
        this.changeLanguage(language)
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
>>> .translations > .v-list-group__header {
  padding-left: 0;
}
.translationsMenu {
  background: white;
}
</style>
