import { TokenService } from '../services/storage.service'
import router from '../router'
import toastService from '@/services/toast.service'
import { i18n } from '@/translations/i18n'

export const errorInterceptor = error => {
  if (
    router.history.current.name !== 'Login' &&
    !error.config.url.includes('AssetGeo') &&
    !error.config.url.includes('ClientDisposalGeofence') &&
    !error.config.url.includes('ClientPresets') &&
    !error.config.url.includes('AssetTirePressure/latest')
  ) {
    if (error.message?.includes('timeout')) {
      toastService.toastrError({
        message: `${error.message} on ${error.config.baseURL}${error.config.url}`
      })
    } else {
      if (error.request.status === 401) {
        TokenService.removeToken()
        TokenService.removeSupportToken()
        TokenService.removeSupportTokenDetails()
        TokenService.removeUserId()
        TokenService.removeCompanySettings()
        TokenService.removeCurrentChildCompany()
        TokenService.removeUsername()
        TokenService.removeUserRole()
        TokenService.removeLanguage()
        TokenService.removeCorrelationId()
        TokenService.removeCompanyFeatures()
        TokenService.removeMonogotoToken()
        router.push('/')
      } else if (
        error.response.data.errorCode === 10909 ||
        error.response.data.errorCode === 10912 ||
        error.response.data.errorCode === 12618
      ) {
        console.error(error)
      } else {
        toastService.toastrError({
          message: i18n.t(`errorCodes.${error.response.data.errorCode}`)
        })
      }
    }
  }
  throw error
}
