export const iconsPaths = [
  'M 3 3 h 14 v 14 h -14 z', //cube
  'M 8.5 1 L 15.5 12 L 2.5 12 L 8.5 1 Z M 2 15 h 14 v 14 h -14 z', // cube with arrow
  'M 3 3 h 14 v 14 h -14 z', //cube
  'M 3 3 h 14 v 14 h -14 z', //cube
  'M 3 3 h 14 v 14 h -14 z', //cube
  'M 8.5 1 L 15.5 12 L 2.5 12 L 8.5 1 Z M 2 15 h 14 v 14 h -14 z', // cube with arrow
  'M 3 3 h 14 v 14 h -14 z', //cube
  'M 3 3 h 14 v 14 h -14 z', //cube
  'M 3 3 h 14 v 14 h -14 z', //cube
  'M 6 0 L 14 0 L 21 4 L 14 14 L 7 14 L 0 4 z',
  'M 3 3 h 14 v 14 h -14 z' //cube
]

export const iconsPathsForTrailer = [
  'M 8.5 1 M 2 2 L 16 2 L 16 17 L 2 17 Z M 2 15 Z M 2 19 L 16 19 L 16 33 L 2 33 Z', //cube with container
  'M 8.5 1 L 15.5 12 L 2.5 12 L 8.5 1 Z M 2 15 L 16 15 L 16 29 L 2 29 Z M 2 15 Z M 2 31 L 16 31 L 16 45 L 2 45 Z', // cube with arrow and container
  'M 8.5 1 M 2 2 L 16 2 L 16 17 L 2 17 Z M 2 15 Z M 2 19 L 16 19 L 16 33 L 2 33 Z', //cube with container
  'M 8.5 1 M 2 2 L 16 2 L 16 17 L 2 17 Z M 2 15 Z M 2 19 L 16 19 L 16 33 L 2 33 Z', //cube with container
  'M 8.5 1 M 2 2 L 16 2 L 16 17 L 2 17 Z M 2 15 Z M 2 19 L 16 19 L 16 33 L 2 33 Z', //cube with container
  'M 8.5 1 L 15.5 12 L 2.5 12 L 8.5 1 Z M 2 15 L 16 15 L 16 29 L 2 29 Z M 2 15 Z M 2 31 L 16 31 L 16 45 L 2 45 Z', // cube with arrow and container
  'M 8.5 1 M 2 2 L 16 2 L 16 17 L 2 17 Z M 2 15 Z M 2 19 L 16 19 L 16 33 L 2 33 Z', //cube with container
  'M 8.5 1 M 2 2 L 16 2 L 16 17 L 2 17 Z M 2 15 Z M 2 19 L 16 19 L 16 33 L 2 33 Z', //cube with container
  'M 8.5 1 M 2 2 L 16 2 L 16 17 L 2 17 Z M 2 15 Z M 2 19 L 16 19 L 16 33 L 2 33 Z', //cube with container
  'M 6 0 L 14 0 L 21 4 L 14 14 L 7 14 L 0 4 z',
  'M 8.5 1 M 2 2 L 16 2 L 16 17 L 2 17 Z M 2 15 Z M 2 19 L 16 19 L 16 33 L 2 33 Z' //cube with container
]

export const iconsColors = [
  '#757575', //unknown grey
  '#66bb6a', // driving green
  '#F9A825', //idling orange
  '#66bb6a', // parked green
  '#F9A825',
  '#66bb6a',
  '#F9A825',
  '#66bb6a',
  '#757575',
  '#757575',
  '#FF0000' //red
]

export const iconsSize = [
  [20, 40],
  [20, 47],
  [20, 40],
  [20, 40],
  [20, 40],
  [20, 47],
  [20, 40],
  [20, 40],
  [20, 40],
  [20, 40]
]

export const iconsAnchor = [
  [10, 20],
  [10, 25],
  [10, 20],
  [10, 20],
  [10, 20],
  [10, 25],
  [10, 20],
  [10, 20],
  [10, 20],
  [10, 20]
]

export const assetIcon = (state, direction, color) => {
  return `<svg
    width="${iconsSize[state][0]}"
    height="${iconsSize[state][1]}"
    style="rotate:z ${direction}deg"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke-width="2px"
      stroke='#ffffff'
      fill="${color || iconsColors[state]}"  
  
      d="${iconsPaths[state]}"
    ></path>
  </svg>`
}

export const assetIconWithTrailer = (state, direction, color) => {
  return `<svg
    width="20"
    height="47"
    style="rotate:z ${direction}deg"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke-width="2px"
      stroke='#ffffff'
      fill="${color || iconsColors[state]}"  
  
      d="${iconsPathsForTrailer[state]}"
    ></path>
  </svg>`
}

export const assetIconRollOff = (state, color) => {
  return `<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 318 159"
  id="vector"
  width="25"
  height="25"
>
  <g id="group">
    <path
      id="path"
      d="M 30 6.4 C 27 9.9 19 18.8 12.3 26.2 L 0.1 39.7 L 4.4 43.2 L 8.6 46.8 L 24.6 28.9 L 40.5 11.1 L 159.2 11 L 277.8 11 L 293.7 28.9 L 309.5 46.8 L 313 43.6 C 314.9 41.9 316.6 40 316.7 39.5 C 316.7 38.9 309.2 29.8 299.8 19.2 L 282.8 0 L 159.1 0.1 L 35.5 0.2 L 30 6.4 Z"
      fill="${color || iconsColors[state]}"  
      stroke-width="1"
    />
    <path
      id="path_1"
      d="M 30.1 29.6 C 18.2 43.1 18 43.3 18 48.1 L 18 53 L 68 53 L 67.8 34.7 L 67.5 16.5 L 54.8 16.2 L 42.2 15.9 L 30.1 29.6 Z M 76 34.5 L 76 53 L 241 53 L 241 16 L 76 16 L 76 34.5 Z M 250 34.5 L 250 53 L 300 53 L 300 48.2 C 299.9 43.6 299.6 43.2 287.8 29.7 L 275.7 16 L 250 16 L 250 34.5 Z M 23.2 65 C 23.4 65.5 33.4 85.6 45.5 109.5 L 67.5 153 L 158.9 152.7 L 250.3 152.5 L 260.7 132.5 C 266.4 121.5 276.7 101.6 283.6 88.2 L 296.1 64 L 159.5 64 C 68.9 64 22.9 64.3 23.2 65 Z"
      fill="${color || iconsColors[state]}"  
      stroke-width="1"
    />
  </g>
</svg>`
}
export const assetIconFrontLoader = (state, color) => {
  return `<svg xmlns="http://www.w3.org/2000/svg" 
  version="1.0" 
  width="25"
  height="25"
  viewBox="0 0 201.000000 251.000000" preserveAspectRatio="xMidYMid meet">
  <g transform="translate(0.000000,251.000000) scale(0.100000,-0.100000)"  
  fill="${color || iconsColors[state]}"  stroke="none">
  <path d="M610 2139 c-153 -4 -216 -10 -248 -22 -74 -28 -194 -95 -277 -155 -68 -49 -80 -62 -83 -90 l-3 -32 763 0 763 -1 155 -97 155 -98 3 -172 2 -172 -20 20 c-29 29 -77 23 -103 -12 -25 -33 -34 -122 -18 -170 6 -19 22 -45 36 -58 82 -77 145 79 236 579 38 211 41 238 30 285 -25 112 -112 172 -270 186 -122 11 -850 17 -1121 9z"/>
  <path d="M53 1688 c19 -298 71 -926 78 -946 5 -12 26 -30 46 -39 21 -10 40 -18 42 -20 1 -1 -4 -12 -13 -25 -25 -35 -21 -94 9 -123 31 -32 50 -31 81 1 22 21 25 32 22 70 -2 24 -2 44 0 44 2 0 121 -34 265 -74 305 -87 636 -176 653 -176 9 0 10 -8 1 -35 -23 -77 35 -159 97 -135 42 15 59 53 54 115 -3 30 -1 55 3 55 8 0 168 106 252 167 l47 35 0 -35 c0 -43 33 -87 65 -87 33 0 65 44 65 89 0 40 -23 81 -46 81 -20 0 -17 14 7 36 39 35 51 82 57 223 5 133 5 133 -16 122 -58 -31 -138 12 -163 87 -19 58 -14 142 10 191 22 42 71 81 104 81 15 0 17 12 17 113 l0 112 -137 85 -138 85 -734 3 -734 3 6 -103z"/>
  </g>
  </svg>`
}
