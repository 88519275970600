import { toolTip } from '@/components/Livetracking/helpers/popUps'
import mapObjects from '@/components/Livetracking/use/mapObjects'
import { i18n } from '@/translations/i18n'
import L from 'leaflet'
import drawHistory from '@/components/History/use/drawHistory'
import router from '@/router'
import dateTimeFormats from '@/components/use/dateTimeFormats'
import { formatDistanceToNow } from 'date-fns'
const { getMapObject } = mapObjects()
const { getAddressName, openPickupDialog } = drawHistory()
const { dateFormatInTimeZone } = dateTimeFormats()

export const containerTooltip = (asset, address) => {
  let content = document.createElement('div')
  const lastMovement = asset?.marker?.currentState?.lastMovement
    ? asset?.marker?.currentState?.lastMovement
    : asset?.lastMovement
  let lastServicedTime = lastMovement
    ? `${formatDistanceToNow(new Date(lastMovement))}`
    : '/'
  let lastServicedDate = lastMovement ? dateFormatInTimeZone(lastMovement) : '/'
  content.innerHTML += `
  <b>Container name:</b> ${asset.name || '/'}<br>
  <b>Last serviced:</b> ${lastServicedDate} (${lastServicedTime}) <br>
  <b>Due date: </b>
`
  content.style.padding = '5px'
  const pickUpDate = document.createElement('i')
  pickUpDate.innerHTML += 'calendar_today'
  pickUpDate.classList.add('material-icons')
  pickUpDate.style.fontSize = '16px'
  pickUpDate.style.cursor = 'pointer'
  pickUpDate.addEventListener('click', () => {
    openPickupDialog.value = true
  })
  content.append(pickUpDate)

  const br = document.createElement('br')
  content.append(br)

  const pickUpJob = document.createElement('button')
  pickUpJob.innerHTML += 'Add pick up job'
  pickUpJob.classList.add('primary')
  pickUpJob.style.color = 'white'
  pickUpJob.style.padding = '5px'
  pickUpJob.style.marginTop = '5px'
  pickUpJob.style.marginBottom = '3px'
  pickUpJob.style.padding = '3px'
  content.append(pickUpJob)
  content.style.marginTop = '15px'
  pickUpJob.addEventListener('click', () => {
    router.push({
      name: 'AddWasteJob',
      query: {
        containerId: asset.assetId
      }
    })
  })

  let lastVehicleAndAddress = document.createElement('div')
  lastVehicleAndAddress.innerHTML += `<b>Serviced vehicle:</b> ${
    asset.gatewayAssetName ? asset.gatewayAssetName : '/'
  }<br>
  <i class="v-icon notranslate mdi mdi-map-marker theme--light" style="font-size: 17px"></i> ${address}`
  content.append(lastVehicleAndAddress)

  return content
}

let lastClickedAsset = null
let lastClickedAddress = null

export const assetOnLeaflet = async asset => {
  let condition =
    asset.type !== 45 &&
    asset.type !== 46 &&
    asset.type !== 4 &&
    asset.type !== 5

  if (asset.assetId === lastClickedAsset?.assetId) {
    showPopUp(asset, lastClickedAddress, condition)
  } else {
    let address = await getAddressName(asset.latitude, asset.longitude)
    lastClickedAsset = asset
    lastClickedAddress = address
    showPopUp(asset, address, condition)
  }
}

function showPopUp(asset, address, condition) {
  if (asset?.marker) {
    getMapObject.value.flyTo(
      asset?.marker?.getLatLng(),
      getMapObject.value.getMaxZoom(),
      {
        animate: false,
        duration: 0
      }
    )
    if (
      String(asset.latitude).charAt(0) !== '0' &&
      String(asset.longitude).charAt(0) !== '0'
    ) {
      asset.marker.options.popUp
        .setLatLng(asset.marker.getLatLng())
        .setContent(
          condition ? toolTip(asset) : containerTooltip(asset, address)
        )
        .openOn(getMapObject.value)
    }
  } else {
    if (
      asset.currentLocation.latitude !== 0 &&
      asset.currentLocation.longitude !== 0
    ) {
      getMapObject.value.flyTo(
        L.latLng(
          asset.currentLocation.latitude,
          asset.currentLocation.longitude
        ),
        getMapObject.value.getMaxZoom(),
        {
          animate: false,
          duration: 0
        }
      )
      if (
        String(asset.currentLocation.latitude).charAt(0) !== '0' &&
        String(asset.currentLocation.longitude).charAt(0) !== '0'
      ) {
        asset.marker?.options?.popUp
          .setLatLng(asset.marker.getLatLng())
          .setContent(
            condition ? toolTip(asset) : containerTooltip(asset, address)
          )
          .openOn(getMapObject.value)
      }
    }
  }
}

export const getAssetState = asset => {
  if (asset?.state === 0 || lastUpdateOlderThan6h(asset)) {
    return 'Unknown'
  } else if (asset?.state === 1) {
    return 'Driving'
  } else if (asset?.state === 2) {
    return 'Idling'
  } else if (asset?.state === 3) {
    return 'Off'
  }
}

export const getAssetStateValue = asset => {
  if (getAssetState(asset?.marker?.currentState || asset) === 'Unknown') {
    return 'Offline'
  } else if (
    getAssetState(asset?.marker?.currentState || asset) === 'Driving'
  ) {
    return i18n.t('global.Driving')
  } else if (getAssetState(asset?.marker?.currentState || asset) === 'Idling') {
    return i18n.t('global.Idle')
  } else if (getAssetState(asset?.marker?.currentState || asset) === 'Off') {
    return 'Off'
  }
}

export const lastUpdateOlderThan6h = asset => {
  return Math.abs(new Date() - new Date(asset?.utcCreated)) > 2.16e7
}

export const assetStateValue = asset => {
  if (lastUpdateOlderThan6h(asset)) {
    return i18n.t('global.Offline')
  } else {
    return getAssetStateValue(asset)
  }
}
