import { ref } from '@vue/composition-api'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'

const companyName = ref('')
const { getChildCompanySettings, getCompanySettings } =
  getCompanySettingsFromToken()
export default function updateSwitchedCompanyData() {
  const updateSwitchedCompanyName = () => {
    if (getChildCompanySettings.value) {
      companyName.value = getChildCompanySettings.value.name
    } else {
      companyName.value = getCompanySettings.value.name
    }
  }

  return {
    companyName,
    updateSwitchedCompanyName
  }
}
