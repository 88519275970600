import router from '@/router'
import { ref } from '@vue/composition-api'
import startAndEndDateTime from '@/components/use/startAndEndDateTime'
import { zonedTimeToUtc } from 'date-fns-tz'
import dateTimeFormats from '@/components/use/dateTimeFormats'
import cloneDeep from 'lodash/cloneDeep'

const { getTimeZone } = dateTimeFormats()
const { dateRange } = startAndEndDateTime()

export default function tablePaging() {
  const tableOptions = ref({
    page: Number(router.currentRoute.query.offset) + 1,
    itemsPerPage: 10
  })
  const routeQuery = ref({
    from: '',
    to: '',
    offset: '0',
    limit: '10',
    term: '',
    sortOrder:
      router.currentRoute.name === 'Alerts' ? 'Descending' : 'Ascending',
    sortField: 'id'
  })
  const updateItemsPerPage = val => {
    tableOptions.value.itemsPerPage = Number(val)
  }
  const updatePage = val => {
    tableOptions.value.page = val
  }
  const routeQueryForDevicesReceivedData = ref({
    imei: 0,
    receivedDataFrom: new Date(dateRange.value.start).toISOString(),
    receivedDataTo: new Date(dateRange.value.end).toISOString(),
    receivedDataOffset: '0',
    receivedDataLimit: '10',
    deviceMessageType: '',
    hasErrors: '',
    hasCanMessage: '',
    hasMobileEyeMessage: ''
  })
  const serverItemsLength = ref(0)

  const createSearchQuery = (includeTime = true) => {
    let query = cloneDeep(router.currentRoute.query)

    if (includeTime) {
      query.from = query.from
        ? new Date(zonedTimeToUtc(query.from, getTimeZone.value)).toISOString()
        : ''
      query.to = query.to
        ? new Date(zonedTimeToUtc(query.to, getTimeZone.value)).toISOString()
        : ''
    } else {
      let queryFromHr = new Date(query.from).setHours(0, 0, 0, 0)
      let queryToHr = new Date(query.to).setHours(23, 59, 59, 999)
      query.from = query.from
        ? new Date(zonedTimeToUtc(queryFromHr, getTimeZone.value)).toISOString()
        : ''
      query.to = query.to
        ? new Date(zonedTimeToUtc(queryToHr, getTimeZone.value)).toISOString()
        : ''
    }
    return JSON.stringify(query)
      .replaceAll(/{|}|"/g, '')
      .split(',')
      .map(item => item.replace(':', '='))
      .join()
      .replaceAll(',', '&')
  }
  const updateDateRange = date => {
    let dateFrom = date[0]
    let dateTo = date[1]
    if (new Date(date[1]) < new Date(date[0])) {
      router.push({
        query: {
          ...router.currentRoute.query,
          from: dateTo,
          to: dateFrom
        }
      })
    } else {
      router.push({
        query: {
          ...router.currentRoute.query,
          from: dateFrom,
          to: dateTo
        }
      })
    }
  }
  const updateServerItemsLength = val => {
    serverItemsLength.value = val
  }
  const updateTableOptions = options => {
    tableOptions.value.itemsPerPage = options.itemsPerPage
    tableOptions.value.page = options.page
    router.push({
      query: {
        ...router.currentRoute.query,
        offset: JSON.stringify(options.page - 1),
        limit: JSON.stringify(options.itemsPerPage),
        sortField: options.sortBy[0] ? options.sortBy[0] : 'id',
        sortOrder:
          options.sortDesc[0] || router.currentRoute.name === 'Alerts'
            ? 'Descending'
            : 'Ascending'
      }
    })
  }

  return {
    tableOptions,
    routeQuery,
    routeQueryForDevicesReceivedData,
    updateTableOptions,
    createSearchQuery,
    updateDateRange,
    serverItemsLength,
    updateServerItemsLength,
    updateItemsPerPage,
    updatePage
  }
}
