import { ref } from '@vue/composition-api'

const showLoader = ref(true)

export default function loader() {
  const toggleLoader = val => {
    showLoader.value = val
  }
  return {
    showLoader,
    toggleLoader
  }
}
