import axios from 'axios'
import { TokenService } from '../services/storage.service'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
import { errorInterceptor } from '@/backend/axiosInterceptors'

const { getToken, getChildCompanySettings, getSupportToken } =
  getCompanySettingsFromToken()

const ApiBackend = {
  // Stores the 401 interceptor position so that it can be later ejected when needed
  _401interceptor: null,
  client: axios.create({
    baseURL: process.env.VUE_APP_WEB_API,
    timeout: 0
  }),

  init() {
    this.client.interceptors.response.use(
      response => response,
      error => errorInterceptor(error)
    )
  },
  setHeader() {
    this.client.defaults.headers['Authorization'] = `Bearer ${getToken.value}`

    this.client.defaults.headers['x-correlation-id'] =
      TokenService.getCorrelationId.value
    this.client.defaults.headers['enctype'] = 'multipart/form-data'
  },

  setSupportAccessToken() {
    this.client.defaults.headers.Authorization = `Bearer ${getSupportToken.value}`
  },
  setLiveShareToken(token) {
    this.client.defaults.headers.Authorization = `${token}`
  },

  setChildCompanyAccessToken() {
    this.client.defaults.headers.Authorization = `Bearer ${getChildCompanySettings.value.token}`
  },

  removeHeader() {
    this.client.defaults.headers = {}
  },

  get(resource) {
    return this.client.get(resource)
  },

  post(resource, data) {
    return this.client.post(resource, data)
  },

  postFile(resource, file) {
    let formData = new FormData()
    formData.append('imageFile', file)
    return this.client.post(resource, formData)
  },

  put(resource, data) {
    return this.client.put(resource, data)
  },

  delete(resource) {
    return this.client.delete(resource)
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return this.client(data)
  },

  mount401Interceptor() {
    this._401interceptor = this.client.interceptors.response.use()
  },

  unmount401Interceptor() {
    // Eject the interceptor
    this.client.interceptors.response.eject(this._401interceptor)
  }
}

export default ApiBackend

ApiBackend.init()
