import axios from 'axios'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
import { errorInterceptor } from '@/backend/axiosInterceptors'
import { TokenService } from '../services/storage.service'

const { getToken, getChildCompanySettings, getSupportToken } =
  getCompanySettingsFromToken()

const HosBackend = {
  client: axios.create({
    baseURL: process.env.VUE_APP_HOS_API,
    timeout: 0
  }),

  init() {
    this.client.interceptors.response.use(
      response => response,
      error => errorInterceptor(error)
    )
    this.client.defaults.headers['Authorization'] = `Bearer ${getToken.value}`
  },
  setHeader() {
    this.client.defaults.headers['Authorization'] = `Bearer ${getToken.value}`

    this.client.defaults.headers['x-correlation-id'] =
      TokenService.getCorrelationId.value
    this.client.defaults.headers['enctype'] = 'multipart/form-data'
  },
  setChildCompanyAccessToken() {
    this.client.defaults.headers.Authorization = `Bearer ${getChildCompanySettings.value.token}`
  },
  setSupportAccessToken() {
    this.client.defaults.headers.Authorization = `Bearer ${getSupportToken.value}`
  },
  get(resource) {
    return this.client.get(resource)
  },

  post(resource, data) {
    return this.client.post(resource, data)
  },

  put(resource, data) {
    return this.client.put(resource, data)
  },

  delete(resource) {
    return this.client.delete(resource)
  }
}

export default HosBackend

HosBackend.init()
