import axios from 'axios'

const NominatimUSBackend = {
  _401interceptor: null,
  url: process.env.VUE_APP_NOMINATIM_US_API,

  init() {
    axios.interceptors.response.use(
      response => response,
      error => {
        throw error
      }
    )
  },

  get(resource) {
    return axios({ method: 'get', url: this.url + resource, timeout: 5000 })
  },

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use()
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor)
  }
}

export default NominatimUSBackend

NominatimUSBackend.init()
