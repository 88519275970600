import { i18n } from '@/translations/i18n'
import { computed } from '@vue/composition-api'
import tablePaging from '@/components/tablePaging'
import startAndEndDateTime from '@/components/use/startAndEndDateTime'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
import { TokenService } from '@/services/storage.service.js'

const { routeQuery } = tablePaging()
const { initialDateRange } = startAndEndDateTime()
const {
  getCompanySettings,
  getCompanyFeatures,
  getUserRoles,
  getChildCompanyFeatures
} = getCompanySettingsFromToken()
export default function menuDropdowns() {
  const administrationDropdown = computed(() => {
    return [
      {
        name: i18n.t('global.Assets'),
        to: {
          name: 'VehiclesAndAssets',
          query: { ...routeQuery.value, active: true }
        },
        enabled: true
      },
      {
        name: i18n.t('global.Groups'),
        to: { name: 'VehicleAndAssetGroups', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('assetAttributes.AssetAttributes'),
        to: { name: 'AssetAttributes', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('global.ImportCCH'),
        to: { name: 'ImportCCH' },
        enabled: ['1021', '1240'].includes(String(companyCCH.companyId))
      },
      {
        divider: true
      },
      {
        name: i18n.t('users.Users'),
        to: { name: 'Users', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('userGroups.UserGroups'),
        to: { name: 'UserGroups', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('customers.Customers'),
        to: { name: 'AllCustomers', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('drivers.Drivers'),
        to: { name: 'Drivers', query: routeQuery.value },
        enabled: true
      },
      {
        divider: true
      },
      {
        name: i18n.t('annualLeave.AnnualLeave'),
        to: { name: 'AnnualLeave', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('annualLeave.ImportFiles'),
        to: { name: 'ImportFile' },
        enabled: true
      },
      {
        divider: true
      },
      {
        name: 'Rules',
        to: { name: 'Rules', query: routeQuery.value },
        enabled: true
      },
      {
        name: 'Scheduled Reports',
        to: {
          name: 'ScheduledReports',
          query: {
            ...routeQuery.value
          }
        },
        enabled: companyFeature.scheduledReports
      }
    ]
  })
  const companyFeature = TokenService.getChildCompanyFeatures.value
    ? TokenService.getChildCompanyFeatures.value
    : TokenService.getCompanyFeatures.value

  const companyCCH = TokenService.getCurrentChildCompany.value
    ? TokenService.getCurrentChildCompany.value
    : TokenService.getCompanySettings.value

  const reportsDropdown = computed(() => {
    const { fromDate, toDate } = getQuarterDates()
    return [
      {
        name: i18n.t('menu.RetentionTime'),
        to: {
          name: 'ReportsByRetentionTimeAtBuyers',
          query: {
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString(),
            term: ''
          }
        },
        enabled: companyFeature.retentionTimeAtBuyerReport
      },
      {
        name: i18n.t('mileageReport.mileageReport'),
        to: {
          name: 'mileageReport',
          query: {
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString(),
            term: ''
          }
        },
        enabled: companyFeature.mileageReport
      },
      {
        name: i18n.t('geoEntryExitTimes.GeoEntryExitTimes'),
        to: {
          name: 'GeofencesEntryExitTimes',
          query: {
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString(),
            term: ''
          }
        },
        enabled: companyFeature.geoFenceEntryExitReport
      },
      {
        name: i18n.t('deviceActivityReport.DeviceActivityReport'),
        to: {
          name: 'DeviceActivityReport',
          query: {
            term: ''
          }
        },
        enabled:
          (companyFeature.deviceActivityReport && isUserAdmin()) ||
          (companyFeature.deviceActivityReport && isSupportUser())
      },
      {
        name: i18n.t('mileageInOut.MileageInOut'),
        to: {
          name: 'MileageInOutReports',
          query: {
            ...routeQuery.value,
            from: new Date(
              new Date(initialDateRange.value.start).setDate(
                new Date(initialDateRange.value.start).getDate() - 1
              )
            ).toISOString(),
            to: new Date(new Date(initialDateRange.value.end)).toISOString()
          }
        },
        enabled: companyFeature.mileageInOutReport
      },
      {
        name: i18n.t('assetLocation.assetLocation'),
        to: {
          name: 'AssetLocationReport',
          query: {
            ...routeQuery.value
          }
        },
        enabled: companyFeature.assetLocationReport,
        dropdown: companyFeature?.wasteManagement
      },
      {
        name: i18n.t('geolocationTripsSummary.GeolocationTripsSummary'),
        to: {
          name: 'HistoryTrips',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled: companyFeature.tripsSummaryReport
      },
      {
        name: i18n.t('mileageOutOfGeofence.MileageOutOfGeofence'),
        to: {
          name: 'MileageOutOfGeofenceReport',
          query: {
            ...routeQuery.value,
            from: new Date(
              new Date(initialDateRange.value.start).setDate(
                new Date(initialDateRange.value.start).getDate() - 1
              )
            ).toISOString(),
            to: new Date(new Date(initialDateRange.value.end)).toISOString()
          }
        },
        enabled: companyFeature.geofenceOutReport
      },
      {
        name: i18n.t('geoHistory.geoHistory'),
        to: {
          name: 'GeoHistoryReport',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled: companyFeature.geoHistoryReport
      },
      {
        name: i18n.t('menu.GeoServedContainers'),
        to: {
          name: 'GeofencesAndServedContainers',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled:
          !getChildCompanyFeatures.value &&
          getCompanySettings.value?.companyId === '7'
      },
      {
        name: 'IFTA/IRP Report',
        to: {
          name: 'IrpReport',
          query: {
            ...routeQuery.value,
            from: fromDate.toISOString(),
            to: toDate.toISOString()
          }
        },
        enabled: companyFeature.iftaReport
      }
    ]
  })
  const getQuarterDates = () => {
    const today = new Date()
    const quarter = Math.floor(today.getMonth() / 3) + 1
    const startMonth = (quarter - 1) * 3
    const fromDate = new Date(today.getFullYear(), startMonth, 1, 0, 0, 0, 0)
    const toDate = new Date(
      today.getFullYear(),
      startMonth + 3,
      0,
      23,
      59,
      59,
      999
    )
    return { fromDate, toDate }
  }
  const historyDropdown = computed(() => {
    return [
      {
        name: i18n.t('menu.AssetHistory'),
        to: {
          name: 'VehicleAndAssetHistory',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString(),
            limit: 25
          }
        },
        enabled: companyFeature.basicTracking
      },
      {
        name: i18n.t('global.WaybillProgress'),
        to: {
          name: 'WaybillProgress',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString(),
            limit: 25
          }
        },
        enabled: companyFeature.wayBillProgress
      }
    ]
  })
  const supportPannelDropdown = computed(() => {
    return [
      {
        name: i18n.t('supportPannel.devices.ManageDevices'),
        to: {
          name: 'SupportPannelDevices',
          query: {
            tab: 'gpsConfiguration',
            term: '',
            offset: 0,
            limit: 10,
            deviceType: '0',
            switchPreview: true
          }
        },
        enabled: true
      },
      {
        name: i18n.t('supportPannel.SwitchCompanies'),
        to: '/SupportPannel/SwitchCompanies/',
        enabled: true
      },
      {
        name: i18n.t('supportPannel.firmwares.Firmwares'),
        to: '/SupportPannel/Firmwares/',
        enabled: true
      },
      {
        name: i18n.t('supportPannel.ManageCompanies'),
        to: { name: 'SupportPannelManageCompanies', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('systemMessages.SystemMessages'),
        to: {
          name: 'SystemMessages',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled: true
      },
      {
        name: i18n.t('devices.BulkCreationOfContainers'),
        to: {
          name: 'BulkCreationOfContainers'
        },
        enabled: true
      },
      {
        name: i18n.t('devices.BulkUpdateOfConfigurationAndFirmware'),
        to: {
          name: 'BulkUpdateOfConfigurationAndFirmware',
          query: {
            ...routeQuery.value
          }
        },
        enabled: true
      }
    ]
  })
  const waybillsDropdown = computed(() => {
    return [
      {
        name: 'Assignments',
        to: '/Dispatch',
        enabled: getChildCompanyFeatures.value
          ? getChildCompanyFeatures.value?.wasteManagement
          : getCompanyFeatures.value?.wasteManagement
      },
      {
        name: i18n.t('global.WayBills'),
        to: {
          name: 'WayBills',
          query: {
            ...routeQuery,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled: getChildCompanyFeatures.value
          ? !getChildCompanyFeatures.value?.wasteManagement
          : !getCompanyFeatures.value?.wasteManagement
      },
      {
        name: i18n.t('global.Routes'),
        to: {
          name: 'Routes',
          query: {
            ...routeQuery.value
          }
        },
        enabled: true
      }
    ]
  })

  const hosDropdown = computed(() => {
    return [
      {
        name: i18n.t('hos.hosDashboard'),
        to: {
          name: 'HosDashboard',
          query: {
            ...routeQuery.value
          }
        },
        enabled: true
      },
      {
        name: i18n.t('hos.HosReport'),
        to: {
          name: 'HosReport',
          query: {
            ...routeQuery.value,
            active: true
          }
        },
        enabled: true
      },
      {
        name: 'Unassigned HOS Report',
        to: {
          name: 'UnassignedHosReport',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled: true
      },
      {
        name: 'DVIR',
        to: {
          name: 'DvirTable',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled: true
      },
      {
        name: i18n.t('hos.eldEvents'),
        to: {
          name: 'EldEvents',
          query: { ...routeQuery.value }
        },
        enabled: true
      },
      {
        name: i18n.t('hos.hosViolations'),
        to: {
          name: 'HosViolations',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled: true
      },
      {
        name: i18n.t('hos.driverLogs'),
        to: {
          name: 'DriverLogs',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled: true
      }
    ]
  })

  const isUserAdmin = () => {
    return getUserRoles.value?.includes('Admin')
  }
  const isSupportUser = () => {
    return getUserRoles.value?.includes('Support')
  }
  return {
    administrationDropdown,
    reportsDropdown,
    historyDropdown,
    waybillsDropdown,
    supportPannelDropdown,
    isUserAdmin,
    routeQuery,
    hosDropdown
  }
}
