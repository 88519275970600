import axios from 'axios'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
import { errorInterceptor } from '@/backend/axiosInterceptors'

const { getToken } = getCompanySettingsFromToken()

const SupportPanelBackend = {
  client: axios.create({
    baseURL: process.env.VUE_APP_WEB_API,
    timeout: 0
  }),

  init() {
    this.client.defaults.headers['Authorization'] = `Bearer ${getToken.value}`
    this.client.interceptors.response.use(
      response => response,
      error => errorInterceptor(error)
    )
  },

  get(resource) {
    return this.client.get(resource)
  },

  post(resource, data) {
    return this.client.post(resource, data)
  },

  put(resource, data) {
    return this.client.put(resource, data)
  },

  delete(resource) {
    return this.client.delete(resource)
  },
  customRequest(data) {
    return this.client(data)
  }
}

export default SupportPanelBackend

SupportPanelBackend.init()
