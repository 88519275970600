var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('v-img',{attrs:{"max-height":"70","max-width":"70","src":_vm.logoImage}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'HelpComponent' })}}},[_c('v-icon',[_vm._v("help_outline")])],1),(_vm.unReviewedAlerts > 0)?_c('v-badge',{attrs:{"overlap":"","color":"red","content":_vm.unReviewedAlerts}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.alertsRoute}},[_c('v-icon',[_vm._v("mdi-bell")])],1)],1):_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.alertsRoute}},[_c('v-icon',[_vm._v("mdi-bell")])],1),_c('v-menu',{attrs:{"z-index":"99999","offset-y":"","transition":"scale-transition","bottom":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("face")])],1)]}}])},[_c('DropdownMenu',{attrs:{"translations":true}})],1),_c('div',[_c('h5',{staticClass:"font-weight-regular",class:{
          'red--text': _vm.supportingUser
        }},[_vm._v(" "+_vm._s((_vm.username + " (" + _vm.companyName + ")"))+" ")]),(_vm.supportingUser)?_c('div',{staticClass:"d-flex align-center red--text"},[_c('h6',[_vm._v(_vm._s(_vm.supportingUser.userName))]),_vm._v(" | "),_vm._l((_vm.supportingUser.userRoles),function(role,i){return _c('div',{key:i,staticStyle:{"font-size":"11px"}},[_c('div',[_vm._v(_vm._s(role))])])})],2):_vm._e()]),(
        !_vm.isViewerUser || _vm.isLivetrackingUser || _vm.isUserAdmin || _vm.isSupportUser
      )?_c('v-app-bar-nav-icon',{nativeOn:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e()],1),_c('v-navigation-drawer',{attrs:{"absolute":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"d-flex justify-end pr-4"},[_c('v-icon',{nativeOn:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v(" close ")])],1)],1),(_vm.basicTrackingFeature)?_c('v-list-item',{attrs:{"to":{ name: 'liveTrackingAssets' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-car-traction-control ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('liveTracking.LiveTracking')))])],1):_vm._e(),(
          (!_vm.isViewerUser ||
            _vm.isLivetrackingUser ||
            _vm.isUserAdmin ||
            _vm.isSupportUser) &&
          _vm.historyIconEnabled
        )?_c('v-list-group',{attrs:{"prepend-icon":"mdi-history"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('global.History')))])],1)]},proxy:true}],null,false,1531401459)},[_c('DropdownMenu',{attrs:{"menu-items":_vm.historyDropdown}})],1):_vm._e(),(
          (!_vm.isViewerUser ||
            _vm.isLivetrackingUser ||
            _vm.isUserAdmin ||
            _vm.isSupportUser) &&
          _vm.reportsIconEnabled
        )?_c('v-list-group',{attrs:{"prepend-icon":"mdi-text-box"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('reports.Reports')))])],1)]},proxy:true}],null,false,3877432570)},[_c('DropdownMenu',{attrs:{"menu-items":_vm.reportsDropdown}})],1):_vm._e(),(
          (!_vm.isViewerUser ||
            _vm.isLivetrackingUser ||
            _vm.isUserAdmin ||
            _vm.isSupportUser) &&
          _vm.waybillslIconEnabled
        )?_c('v-list-group',{attrs:{"prepend-icon":"mdi-truck-delivery"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('global.Dispatch')))])],1)]},proxy:true}],null,false,3358204961)},[_c('DropdownMenu',{attrs:{"menu-items":_vm.waybillsDropdown,"dispatch-mini-variant":true}})],1):_vm._e(),(
          (!_vm.isViewerUser && !_vm.isLivetrackingUser) ||
          _vm.isUserAdmin ||
          _vm.isSupportUser
        )?_c('v-list-item',{attrs:{"to":{ name: 'Geofences', query: _vm.routeQuery }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" pentagon ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('geofences.Geofences')))])],1):_vm._e(),(
          (!_vm.isViewerUser && !_vm.isLivetrackingUser) ||
          _vm.isUserAdmin ||
          _vm.isSupportUser
        )?_c('v-list-item',{attrs:{"to":{ name: 'Documents', query: _vm.routeQuery }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" description ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('documents.Documents')))])],1):_vm._e(),(
          ((!_vm.isViewerUser && !_vm.isLivetrackingUser) ||
            _vm.isUserAdmin ||
            _vm.isSupportUser) &&
          _vm.administrationIconEnabled
        )?_c('v-list-group',{attrs:{"prepend-icon":"mdi-account-multiple"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('administration.Administration')))])],1)]},proxy:true}],null,false,1345993786)},[_c('DropdownMenu',{attrs:{"menu-items":_vm.administrationDropdown}})],1):_vm._e(),(_vm.getEldValue)?_c('v-list-group',{attrs:{"prepend-icon":"badge"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('hos.compliance')))])],1)]},proxy:true}],null,false,779682419)},[_c('DropdownMenu',{attrs:{"menu-items":_vm.hosDropdown}})],1):_vm._e(),(_vm.isSupportUser && !_vm.supportingUser && _vm.supportPanelIconEnabled)?_c('v-list-group',{attrs:{"prepend-icon":"mdi-face-agent"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('supportPannel.SupportPannel')))])],1)]},proxy:true}],null,false,1276623162)},[_c('DropdownMenu',{attrs:{"menu-items":_vm.supportPannelDropdown,"support-pannel":true}})],1):_vm._e(),(_vm.isUserAdmin || _vm.isSupportUser)?_c('v-list-group',{attrs:{"prepend-icon":"mdi-cog"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('global.Settings')))])],1)]},proxy:true}],null,false,3502851988)},[_c('v-list',[_c('v-list-item',{staticClass:"font-weight-medium text-caption px-10",attrs:{"to":{ name: 'CompanySettings' }}},[_vm._v(" "+_vm._s(_vm.$t('companySettings.General')))]),_c('v-list-item',{staticClass:"font-weight-medium text-caption px-10",attrs:{"to":{ name: 'BasicTracking' }}},[_vm._v(" "+_vm._s(_vm.$t('companySettings.Features'))+" ")]),_c('v-list-item',{staticClass:"px-10 font-weight-medium text-caption",attrs:{"to":{ name: 'Integrations' }}},[_vm._v(" Integrations ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }