var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column",staticStyle:{"background-color":"white","overflow-y":"auto"}},[(_vm.dispatch)?_c('v-menu',{attrs:{"content-class":"translationsMenu","offset-x":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isDispatchEnabled)?_c('v-list-item',_vm._g({staticClass:"d-flex justify-space-between px-10"},on),[_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(_vm._s(_vm.$t('global.WayBills')))]),_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e()]}}],null,false,1964811951)},[_c('v-list-item',{staticClass:"ma-2",attrs:{"to":{
        name: 'WayBills',
        query: Object.assign({}, _vm.routeQuery,
          {from: new Date(_vm.initialDateRange.start).toISOString(),
          to: new Date(_vm.initialDateRange.end).toISOString()})
      }}},[_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(" Front Loader ")])],1),_c('v-list-item',{staticClass:"ma-2",attrs:{"to":{
        name: 'JobsRolloff',
        query: Object.assign({}, _vm.routeQuery)
      }}},[_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(" Roll off ")])],1)],1):_vm._e(),(_vm.dispatchMiniVariant)?_c('v-list',[(_vm.isDispatchEnabled)?_c('v-list-group',{staticClass:"translations",scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"px-10"},[_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(_vm._s(_vm.$t('global.WayBills')))])],1)]},proxy:true}],null,false,1046877398)},[_c('v-list-item-group',{model:{value:(_vm.jobsMenu),callback:function ($$v) {_vm.jobsMenu=$$v},expression:"jobsMenu"}},[_c('v-list-item',{staticClass:"ml-5",attrs:{"to":{
            name: 'WayBills',
            query: Object.assign({}, _vm.routeQuery,
              {from: new Date(_vm.initialDateRange.start).toISOString(),
              to: new Date(_vm.initialDateRange.end).toISOString()})
          }}},[_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(" Front Loader ")])],1),_c('v-list-item',{staticClass:"ml-5",attrs:{"to":{
            name: 'JobsRolloff',
            query: Object.assign({}, _vm.routeQuery)
          }}},[_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(" Roll off ")])],1)],1)],1):_vm._e()],1):_vm._e(),(_vm.translations)?_c('v-list',[_c('v-list-item',{staticClass:"px-10",attrs:{"to":{ name: 'userProfile' }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v(" mdi-account ")])],1),_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(_vm._s(_vm.$t('userProfile.UserProfile')))])],1),(!_vm.miniVariant)?_c('v-list-group',{staticClass:"translations",scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"px-10"},[_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(_vm._s(_vm.$t('global.Language')))])],1)]},proxy:true}],null,false,19988659)},[_c('v-list-item-group',{model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}},_vm._l((_vm.translationsList),function(item,i){return _c('v-list-item',{key:i,staticClass:"ml-5",on:{"click":function($event){return _vm.onClick(item.value)}}},[_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),1)],1):_c('v-menu',{attrs:{"content-class":"translationsMenu","offset-x":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"d-flex justify-space-between px-10"},on),[_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(_vm._s(_vm.$t('global.Language')))]),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}],null,false,2680678874)},_vm._l((_vm.translationsList),function(item,i){return _c('v-list-item',{key:i,staticClass:"ma-2",on:{"click":function($event){return _vm.onClick(item.value)}}},[_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),1),(_vm.translations && _vm.supportingUser)?_c('v-list-item',{staticClass:"px-10 font-weight-medium text-caption",on:{"click":_vm.logOutAsCustomer}},[_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(" "+_vm._s(((_vm.$t('menu.SignOutAs')) + " " + (_vm.supportingUser.userName)))+" ")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("logout")])],1)],1):_vm._e(),(_vm.user && !_vm.supportingUser)?_c('v-list-item',{staticClass:"font-weight-medium text-caption px-10",on:{"click":_vm.logOut}},[_c('v-list-item-title',{staticClass:"font-weight-medium text-caption"},[_vm._v(" "+_vm._s(_vm.logout.name)+" ")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("logout")])],1)],1):_vm._e()],1):_vm._l((_vm.menuItems),function(item,i){return [(item.enabled)?_c('v-list-item',{key:i,staticClass:"px-10 font-weight-medium text-caption d-flex justify-space-between",attrs:{"exact":"","to":!item.jobsDropdown && !item.jobsDropdownTable && !item.dropdown
          ? item.to
          : ''}},[_vm._v(" "+_vm._s(item.name)+" "),(item.jobsDropdown)?_c('v-menu',{key:i,attrs:{"offset-x":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-chevron-right")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"px-10 font-weight-medium text-caption",attrs:{"exact":"","to":item.to}},[_vm._v("Front Loader")]),_c('v-list-item',{staticClass:"px-10 font-weight-medium text-caption",attrs:{"exact":"","to":'/Jobs/AddWasteJob'}},[_vm._v("Roll off")])],1)],1):_vm._e(),(item.dropdown)?_c('v-menu',{key:i,attrs:{"offset-x":"","transition":"scale-transition","z-index":"99999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-chevron-right")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"px-10 font-weight-medium text-caption",attrs:{"exact":"","to":{
              name: item.to.name,
              query: Object.assign({}, item.to.query,
                {vehicle: true,
                frontLoader: undefined,
                rollOff: undefined})
            }}},[_vm._v("Vehicle")]),_c('v-list-item',{staticClass:"px-10 font-weight-medium text-caption",attrs:{"exact":"","to":{
              name: item.to.name,
              query: Object.assign({}, item.to.query,
                {frontLoader: true,
                vehicle: undefined,
                rollOff: undefined})
            }}},[_vm._v("Front Loader")]),_c('v-list-item',{staticClass:"px-10 font-weight-medium text-caption",attrs:{"exact":"","to":{
              name: item.to.name,
              query: Object.assign({}, item.to.query,
                {frontLoader: undefined,
                vehicle: undefined,
                rollOff: true})
            }}},[_vm._v("Roll off")])],1)],1):_vm._e()],1):_vm._e(),(item.divider)?_c('v-divider',{key:i}):_vm._e()]}),(_vm.supportPannel)?_c('v-list-item',{staticClass:"px-10 font-weight-medium text-caption",attrs:{"to":_vm.pinLoginRoute}},[_vm._v(" "+_vm._s(_vm.$t('supportPannel.PinLogin'))+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }