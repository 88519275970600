import LivetrackingService from '@/services/livetracking/LivetrackingService'
import { ref } from '@vue/composition-api'
import L from 'leaflet'
import loader from '@/components/Livetracking/use/loader'
import {
  assetIcon,
  assetIconWithTrailer,
  assetIconRollOff,
  assetIconFrontLoader,
  iconsAnchor,
  iconsSize
} from '@/helpers/assetsIcons'
import { toolTip } from '@/components/Livetracking/helpers/popUps'
import { assetOnLeaflet } from '@/components/Livetracking/helpers/assetsMethods'
import mapObjects from '@/components/Livetracking/use/mapObjects'
import router from '@/router'
import AlertsService from '@/services/alerts/AlertsService.js'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
import toastService from '@/services/toast.service'
import isEqual from 'lodash/isEqual'

const { toggleLoader } = loader()
const assets = ref([])
const filteredAssets = ref([])
const assetsMarkers = ref(null)
const activeAssetId = ref(null)
const leafletMap = ref(null)
const { updateIsMarkerCreated } = mapObjects()
const showFilteredAssets = ref(false)
const isPickUpPopUpOppened = ref(false)
const unReviewedAlerts = ref(0)
const alertsWorker = ref(null)
const alerts = ref([])
const activeAlerts = ref(null)
const {
  getCompanySettings,
  getChildCompanySettings,
  getToken,
  getSupportToken
} = getCompanySettingsFromToken()
export default function useAssets() {
  const iconsForAssetTypes = asset => {
    const typeVans = [2, 38, 39, 40, 41, 42, 43, 44]
    if (asset.type === 1) {
      asset.imagePath = require('@/images/icons/car-list.png')
    } else if (typeVans.includes(asset.type)) {
      asset.imagePath = require('@/images/icons/van-list.png')
    } else if (asset.type === 3) {
      asset.imagePath = require('@/images/icons/truck-list.png')
    } else if (asset.type === 4) {
      asset.imagePath = require('@/images/icons/trailer-list.png')
    } else if (asset.type === 5) {
      asset.imagePath = require('@/images/icons/yard-reader.png')
    } else if (asset.type === 6) {
      asset.imagePath = require('@/images/icons/dumper-list.png')
    } else if (asset.type === 7) {
      asset.imagePath = require('@/images/icons/van-list.png')
    } else if (asset.type === 8) {
      asset.imagePath = require('@/images/icons/truck-list.png')
    } else if (asset.type === 9) {
      asset.imagePath = require('@/images/icons/bus.png')
    } else if (asset.subType === 48 || asset.type === 45) {
      asset.imagePath = require('@/images/front-loader.png')
    } else if (asset.subType === 0 && asset.type === 46) {
      asset.imagePath = require('@/images/roll-off.png')
    } else if (asset.subType === 47) {
      asset.imagePath = require('@/images/roll-off.png')
    }
  }
  const updateUnReviewedAlerts = (val, update) => {
    if (update === 1) {
      unReviewedAlerts.value = val
    } else {
      unReviewedAlerts.value = unReviewedAlerts.value + val
    }
  }
  const updateReviewedAlerts = val => {
    unReviewedAlerts.value = unReviewedAlerts.value - val
  }
  const openPickUpPopUp = val => {
    isPickUpPopUpOppened.value = val
  }
  const updateShowFilteredAssets = val => {
    showFilteredAssets.value = val
  }
  const closeAlertsConnection = () => {
    alertsWorker.value.terminate()
  }
  const initAssets = async () => {
    toggleLoader(true)
    try {
      const { data } = await LivetrackingService.getAllAssets()
      data.forEach(asset => {
        iconsForAssetTypes(asset)
      })
      assets.value = data.reduce((dictionary, asset) => {
        dictionary[asset.assetId] = asset
        return dictionary
      }, {})
      filteredAssets.value = assets.value
      if (leafletMap.value) {
        createMarkersOnLeaflet(assets.value, leafletMap.value)
      }
      toggleLoader(false)
    } catch (error) {
      toggleLoader(false)
    }
  }
  const getLeafletMap = val => {
    leafletMap.value = val
  }
  const mapIconsForType = (asset, state, color) => {
    if (asset.subType === 47 || (asset.subType === 0 && asset.type === 46)) {
      return assetIconRollOff(state, color)
    } else if (asset.subType === 48 || asset.type === 45) {
      return assetIconFrontLoader(state, color)
    } else {
      if (asset.assetHaulingInformation.length > 0) {
        return assetIconWithTrailer(state, asset.direction, color)
      } else if (asset.assetHaulingInformation.length === 0) {
        return assetIcon(state, asset.direction, color)
      }
    }
  }
  const createMarkersOnLeaflet = (val, map) => {
    let bounds = []
    Object.keys(val).forEach(assetId => {
      if (
        String(assets.value[assetId].latitude).charAt(0) !== '0' &&
        String(assets.value[assetId].longitude).charAt(0) !== '0'
      ) {
        let state = assets.value[assetId].state
        if (
          Math.abs(new Date() - new Date(assets.value[assetId].utcCreated)) >
          2.16e7
        ) {
          state = 8
        }
        state =
          assets.value[assetId].type === 45 || assets.value[assetId].type === 46
            ? 9
            : state
        let marker = L.movingMarker(
          L.latLng(
            assets.value[assetId].latitude,
            assets.value[assetId].longitude
          ),
          {
            icon: L.divIcon({
              iconSize: iconsSize[state],
              iconAnchor: iconsAnchor[state],
              className: '',
              html: mapIconsForType(assets.value[assetId], state)
            }),
            currentState: assets.value[assetId],
            state: assets.value[assetId].state,
            assetId: assets.value[assetId].assetId,
            utcReceived: assets.value[assetId].lastUpdate,
            popUp: L.popup(
              L.latLng(
                assets.value[assetId].latitude,
                assets.value[assetId].longitude
              ),
              {
                direction: 'top',
                offset: [0, -10],
                closeButton: true
              }
            ),
            draggable: false
          }
        )
        if (assets.value[assetId].assetHaulingInformation.length > 0) {
          marker.setZIndexOffset(1000)
        }
        marker.on('click', () => {
          assetOnLeaflet(assets.value[assetId])
          openAsset(assets.value[assetId])
        })
        marker.on('mouseover', e => {
          e.target.options.popUp
            .setLatLng(e.target.getLatLng())
            .setContent(toolTip(assets.value[assetId]))
            .openOn(map)
        })
        assets.value[assetId].marker = marker
        assetsMarkers.value?.addLayer(assets.value[assetId].marker)
        bounds.push([
          assets.value[assetId]?.latitude,
          assets.value[assetId]?.longitude
        ])
        if (activeAlerts.value) {
          if (
            Object.keys(activeAlerts.value).includes(
              String(assets.value[assetId]?.assetId)
            )
          ) {
            if (!assets.value[assetId].marker.currentState?.alert) {
              assets.value[assetId].marker.currentState = {
                ...assets.value[assetId].marker.currentState,
                alert: activeAlerts.value[assetId]
              }
            } else {
              assets.value[assetId].marker.currentState.alert.push(
                activeAlerts.value[assetId]
              )
            }
            let newIconHtml = mapIconsForType(
              assets.value[assetId],
              assets.value[assetId].state,
              '#FF0000'
            )
            let marker = assets.value[assetId].marker
            if (marker) {
              let currentIconOptions = marker.options.icon.options
              let newIcon = L.divIcon({
                iconSize: currentIconOptions.iconSize,
                iconAnchor: currentIconOptions.iconAnchor,
                className: currentIconOptions.className,
                html: newIconHtml
              })
              marker.setIcon(newIcon)
            }
          }
        }
        if (assets.value[assetId].powerSource === 1) {
          let newIconHtml = mapIconsForType(
            assets.value[assetId],
            assets.value[assetId].state,
            '#FF0000'
          )
          let marker = assets.value[assetId].marker
          if (marker) {
            let currentIconOptions = marker.options.icon.options
            let newIcon = L.divIcon({
              iconSize: currentIconOptions.iconSize,
              iconAnchor: currentIconOptions.iconAnchor,
              className: currentIconOptions.className,
              html: newIconHtml
            })
            marker.setIcon(newIcon)
          }
        }
      }
    })
    if (bounds.length) {
      map.fitBounds(bounds)
    }
    updateIsMarkerCreated(true)
  }
  const openAsset = asset => {
    const path = `/LiveTracking/vehiclesAndAssets/${asset.assetId}`
    router.push(path)
    setClickActive(asset.assetId, true)
  }
  const updateAlert = updateAlert => {
    if (updateAlert) {
      if (updateAlert.active === true) {
        if (!alerts.value.some(alert => alert.name === updateAlert.name)) {
          alerts.value.push(updateAlert)
        }
        if (
          assets.value[updateAlert.assetId] &&
          assets.value[updateAlert.assetId].alert?.length > 0
        ) {
          if (
            !assets.value[updateAlert.assetId].alert?.some(
              alert => alert.name === updateAlert.name
            )
          ) {
            assets.value[updateAlert.assetId].alert?.push(updateAlert)
          }
        }
        if (filteredAssets.value[updateAlert.assetId]?.marker) {
          if (
            !filteredAssets.value[
              updateAlert.assetId
            ].marker.currentState.alert?.some(
              alert => alert.name === updateAlert.name
            )
          ) {
            filteredAssets.value[
              updateAlert.assetId
            ].marker.currentState.alert?.push(updateAlert)
          }
        }

        let newIconHtml = mapIconsForType(
          assets.value[updateAlert.assetId],
          assets.value[updateAlert.assetId].state,
          '#FF0000'
        )
        let marker = assets.value[updateAlert.assetId]?.marker
        let currentIconOptions = marker.options.icon.options
        let newIcon = L.divIcon({
          iconSize: currentIconOptions.iconSize,
          iconAnchor: currentIconOptions.iconAnchor,
          className: currentIconOptions.className,
          html: newIconHtml
        })
        marker.setIcon(newIcon)
        if (filteredAssets.value[updateAlert.assetId].marker) {
          filteredAssets.value[updateAlert.assetId].marker.currentState = {
            ...filteredAssets.value[updateAlert.assetId],
            alert: [updateAlert]
          }
        }
      }
    }
    if (updateAlert.active === false) {
      const index = alerts.value.findIndex(alert => alert.id === updateAlert.id)
      if (index !== -1) {
        alerts.value.splice(index, 1)
      }
      if (
        assets.value[updateAlert.assetId] &&
        assets.value[updateAlert.assetId].alert
      ) {
        assets.value[updateAlert.assetId].alert = assets.value[
          updateAlert.assetId
        ].alert.filter(alert => alert.name !== updateAlert.name)
      }
    }
  }
  const getActiveAlerts = async () => {
    try {
      activeAlerts.value = await AlertsService.getAllAlertsForCompany(
        `Offset=0&Limit=100000&Active=true&CompanyId=${
          getChildCompanySettings.value
            ? getChildCompanySettings.value.companyId
            : getCompanySettings.value.companyId
        }`
      )
      toggleLoader(false)
    } catch (error) {
      toggleLoader(false)
    }
  }
  const startAlertConnection = async () => {
    let token = getToken.value
    if (getChildCompanySettings.value?.token) {
      token = getChildCompanySettings.value.token
    }
    if (getSupportToken.value) {
      token = getSupportToken.value
    }
    alertsWorker.value = new Worker('/alertsWorker.js')
    alertsWorker.value.onmessage = e => {
      if (e.data.error) {
        toastService.toastrError({
          message: 'Network Error'
        })
      } else {
        alerts.value.push(e.data)
        assets.value[e.data.assetId] = {
          ...assets.value[e.data.assetId],
          updatedAsset: {
            ...e.data
          }
        }
        if (e.data.active === true) {
          toastService.toastrAlert({
            message:
              'Alert: ' +
              e.data.name +
              ' Driver: ' +
              assets.value[e.data.assetId].name
          })
        }
        updateUnReviewedAlerts(1, 2)
        return updateAlert(e.data)
      }
    }
    alertsWorker.value.postMessage([token, process.env.VUE_APP_HUB])
  }
  const alertMarkers = async assets => {
    await getActiveAlerts()
    for (let key in activeAlerts.value) {
      if (assets.value[key]) {
        if (!assets.value[key].marker.currentState?.alert) {
          assets.value[key].marker.currentState = {
            ...assets.value[key].marker.currentState,
            alert: activeAlerts.value[key]
          }
        } else {
          assets.value[key].marker.currentState.alert.push(
            activeAlerts.value[key]
          )
        }

        let newIconHtml = mapIconsForType(
          assets.value[key],
          assets.value[key].state,
          '#FF0000'
        )
        let marker = assets.value[key].marker
        if (marker) {
          let currentIconOptions = marker.options.icon.options
          let newIcon = L.divIcon({
            iconSize: currentIconOptions.iconSize,
            iconAnchor: currentIconOptions.iconAnchor,
            className: currentIconOptions.className,
            html: newIconHtml
          })
          marker.setIcon(newIcon)
        }
      }
    }
  }
  const updateAssets = updatedAsset => {
    if (assets.value[updatedAsset.assetId]) {
      let filteredAlerts = alerts.value.filter(
        a => a.assetId === assets.value[updatedAsset.assetId].assetId
      )
      if (alerts.value.length > 0 && filteredAlerts.length > 0) {
        assets.value[updatedAsset.assetId] = {
          ...assets.value[updatedAsset.assetId],
          alert: filteredAlerts
        }
        if (filteredAssets.value[updatedAsset.assetId]) {
          filteredAssets.value[updatedAsset.assetId] = {
            ...filteredAssets.value[updatedAsset.assetId],
            alert: filteredAlerts
          }
        }

        let newIconHtml = mapIconsForType(
          assets.value[updatedAsset.assetId],
          assets.value[updatedAsset.assetId].state,
          '#FF0000'
        )
        let marker = assets.value[updatedAsset.assetId].marker
        if (marker) {
          let currentIconOptions = marker.options.icon.options
          let newIcon = L.divIcon({
            iconSize: currentIconOptions.iconSize,
            iconAnchor: currentIconOptions.iconAnchor,
            className: currentIconOptions.className,
            html: newIconHtml
          })
          marker.setIcon(newIcon)
        }
      } else {
        assets.value[updatedAsset.assetId] = {
          ...assets.value[updatedAsset.assetId],
          ...updatedAsset
        }
        if (filteredAssets.value[updatedAsset.assetId]) {
          filteredAssets.value[updatedAsset.assetId] = {
            ...filteredAssets.value[updatedAsset.assetId],
            ...updatedAsset
          }
        }
      }
      if (updatedAsset.assetHaulingInformation) {
        if (updatedAsset.assetHaulingInformation.length > 0) {
          if (assets.value[updatedAsset.assetId]) {
            const assetHaulingInfo =
              assets.value[updatedAsset.assetId].assetHaulingInformation
            updatedAsset.assetHaulingInformation.forEach(info => {
              if (
                !assetHaulingInfo.some(existingInfo =>
                  isEqual(existingInfo, info)
                )
              ) {
                assetHaulingInfo.push(info)
              }
            })
          }

          if (filteredAssets.value[updatedAsset.assetId]) {
            const filteredAssetHaulingInfo =
              filteredAssets.value[updatedAsset.assetId].assetHaulingInformation
            updatedAsset.assetHaulingInformation.forEach(info => {
              if (
                !filteredAssetHaulingInfo.some(existingInfo =>
                  isEqual(existingInfo, info)
                )
              ) {
                filteredAssetHaulingInfo.push(info)
              }
            })
          }
        } else {
          if (assets.value[updatedAsset.assetId]) {
            assets.value[updatedAsset.assetId].assetHaulingInformation = []
          }
          if (filteredAssets.value[updatedAsset.assetId]) {
            filteredAssets.value[updatedAsset.assetId].assetHaulingInformation =
              []
          }
        }
      }

      if (updatedAsset.faultCodes) {
        if (updatedAsset.faultCodes.length > 0) {
          if (assets.value[updatedAsset.assetId]) {
            const assetFaultCodes =
              assets.value[updatedAsset.assetId].faultCodes
            updatedAsset.faultCodes.forEach(info => {
              if (
                !assetFaultCodes.some(existingInfo =>
                  isEqual(existingInfo, info)
                )
              ) {
                assetFaultCodes.push(info)
              }
            })
          }

          if (filteredAssets.value[updatedAsset.assetId]) {
            const filteredFaultCodes =
              filteredAssets.value[updatedAsset.assetId].faultCodes
            updatedAsset.faultCodes.forEach(info => {
              if (
                !filteredFaultCodes.some(existingInfo =>
                  isEqual(existingInfo, info)
                )
              ) {
                filteredFaultCodes.push(info)
              }
            })
          }
        } else {
          if (assets.value[updatedAsset.assetId]) {
            assets.value[updatedAsset.assetId].faultCodes = []
          }
          if (filteredAssets.value[updatedAsset.assetId]) {
            filteredAssets.value[updatedAsset.assetId].faultCodes = []
          }
        }
      }
      if (updatedAsset.latitude !== 0 && updatedAsset.longitude !== 0) {
        let oldTime = new Date(
          assets.value[updatedAsset.assetId].utcCreated
        ).getTime()
        let newTime = new Date(
          updatedAsset.utcCreated.includes('Z')
            ? updatedAsset.utcCreated
            : updatedAsset.utcCreated.concat('Z')
        ).getTime()
        let miliseconds = newTime - oldTime
        let newPosition = L.latLng(
          updatedAsset.latitude,
          updatedAsset.longitude
        )
        if (Math.sign(miliseconds) === -1) {
          return
        }
        if (updatedAsset.distance < 0.05 || miliseconds <= 0) {
          miliseconds = 100
        }
        assets.value[updatedAsset.assetId]?.marker?.moveTo(
          newPosition,
          miliseconds,
          assets.value[updatedAsset.assetId]
        )
      }
      if (updatedAsset.powerSource === 1) {
        let newIconHtml = mapIconsForType(
          assets.value[updatedAsset.assetId],
          assets.value[updatedAsset.assetId].state,
          '#FF0000'
        )
        let marker = assets.value[updatedAsset.assetId].marker
        if (marker) {
          let currentIconOptions = marker.options.icon.options
          let newIcon = L.divIcon({
            iconSize: currentIconOptions.iconSize,
            iconAnchor: currentIconOptions.iconAnchor,
            className: currentIconOptions.className,
            html: newIconHtml
          })
          marker.setIcon(newIcon)
        }
      }
    }
  }
  const powerSourceMarkers = async assets => {
    for (let key in assets.value) {
      if (assets.value[key].powerSource === 1) {
        let newIconHtml = mapIconsForType(
          assets.value[key],
          assets.value[key].state,
          '#FF0000'
        )
        let marker = assets.value[key].marker
        if (marker) {
          let currentIconOptions = marker.options.icon.options
          let newIcon = L.divIcon({
            iconSize: currentIconOptions.iconSize,
            iconAnchor: currentIconOptions.iconAnchor,
            className: currentIconOptions.className,
            html: newIconHtml
          })
          marker.setIcon(newIcon)
        }
      }
    }
  }
  const removeAllAssets = () => {
    setAssetActive(null)
    assets.value = ref([])
    filteredAssets.value = ref([])
  }

  const updateFilteredAssets = value => {
    filteredAssets.value = value
  }

  const setClickActive = (assetId, value) => {
    filteredAssets.value[assetId] = {
      ...filteredAssets.value[assetId],
      isClickActive: value
    }
  }

  const updateAssetMarkers = value => {
    assetsMarkers.value = value
  }

  const setAssetActive = assetId => {
    activeAssetId.value = assetId
  }

  return {
    initAssets,
    filteredAssets,
    updateFilteredAssets,
    updateAssets,
    assets,
    activeAssetId,
    setAssetActive,
    setClickActive,
    removeAllAssets,
    assetsMarkers,
    updateAssetMarkers,
    iconsForAssetTypes,
    createMarkersOnLeaflet,
    getLeafletMap,
    showFilteredAssets,
    openPickUpPopUp,
    isPickUpPopUpOppened,
    updateShowFilteredAssets,
    unReviewedAlerts,
    updateUnReviewedAlerts,
    alertsWorker,
    updateAlert,
    alertMarkers,
    powerSourceMarkers,
    updateReviewedAlerts,
    closeAlertsConnection,
    startAlertConnection
  }
}
