import { ref } from '@vue/composition-api'

const supportingUser = ref(null)

export default function updateSupportingUserData() {
  const updateSupportingUsernameAndRoles = value => {
    supportingUser.value = value
  }

  return {
    supportingUser,
    updateSupportingUsernameAndRoles
  }
}
