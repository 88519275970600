import { ref } from '@vue/composition-api'
import cloneDeep from 'lodash-es/cloneDeep'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'
const timezone = JSON.parse(localStorage.getItem('current_child_company'))
  ? JSON.parse(localStorage.getItem('current_child_company'))?.timeZone
  : JSON.parse(localStorage.getItem('company_settings'))?.timeZone
const initialDates = ref({
  start: new Date(
    new Date(utcToZonedTime(new Date().toISOString(), timezone)).setHours(
      0,
      0,
      0,
      0
    )
  ).toISOString(),
  end: new Date(
    new Date(utcToZonedTime(new Date().toISOString(), timezone)).setHours(
      23,
      59,
      59,
      999
    )
  ).toISOString()
})
const initialDateRange = cloneDeep(initialDates)
const dateRange = ref({
  start: utcToZonedTime(
    zonedTimeToUtc(initialDateRange.value.start, timezone).toISOString(),
    timezone
  ),
  end: utcToZonedTime(
    zonedTimeToUtc(initialDateRange.value.end, timezone).toISOString(),
    timezone
  )
})
export default function startAndEndDateTime() {
  const setStartAndEndDateTime = value => {
    dateRange.value = value
  }
  const setStartAndEndDate = value => {
    let start = value[0].split('-')
    let end = value[1].split('-')
    let startDate = new Date(start[0], Number(start[1]) - 1, start[2])
    let endDate = new Date(end[0], Number(end[1]) - 1, end[2])
    const startHours = new Date(dateRange.value.start).getHours()
    const startMinutes = new Date(dateRange.value.start).getMinutes()
    const endHours = new Date(dateRange.value.end).getHours()
    const endMinutes = new Date(dateRange.value.end).getMinutes()

    if (startDate > endDate) {
      ;[startDate, endDate] = [endDate, startDate]
    }
    dateRange.value.start = new Date(
      startDate.setHours(startHours, startMinutes)
    )
    dateRange.value.end = new Date(endDate.setHours(endHours, endMinutes))
  }
  const setStartTime = value => {
    if (value) {
      let time = value.split(':')
      let hours = time[0]
      let minutes = time[1]
      let date = new Date(dateRange.value.start)
      dateRange.value.start = new Date(
        date.setHours(hours, minutes)
      ).toISOString()
    }
  }
  const setEndTime = value => {
    if (value) {
      let time = value.split(':')
      let hours = time[0]
      let minutes = time[1]
      let date = new Date(dateRange.value.end)
      dateRange.value.end = new Date(
        date.setHours(hours, minutes)
      ).toISOString()
    }
  }
  return {
    dateRange,
    initialDateRange,
    setStartAndEndDateTime,
    setStartAndEndDate,
    setStartTime,
    setEndTime
  }
}
