import { ref } from '@vue/composition-api'

const getMapObject = ref(null)
const zoom = ref(7)
const center = ref({ lat: 44.292250834159454, lng: 21.249834088573927 })
const isMarkerCreated = ref(false)

export default function mapObjects() {
  const setMapObject = mapObject => {
    getMapObject.value = mapObject
  }

  const setZoom = val => {
    zoom.value = val
  }
  const setCenter = val => {
    center.value = val
  }
  const updateIsMarkerCreated = val => {
    isMarkerCreated.value = val
  }
  return {
    getMapObject,
    setMapObject,
    zoom,
    setZoom,
    center,
    setCenter,
    updateIsMarkerCreated,
    isMarkerCreated
  }
}
