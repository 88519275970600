import { ref } from '@vue/composition-api'
import NominatimService from '@/services/history/NominatimService.js'
import dateTimeFormats from '@/components/use/dateTimeFormats'
import loader from '@/components/Livetracking/use/loader'
import HistoryService from '@/services/history/HistoryService.js'
import tablePaging from '@/components/tablePaging'
import L from 'leaflet'
const { toggleLoader } = loader()
const { createSearchQuery } = tablePaging()
const historyTrips = ref([])
const historyTripPois = ref([])
const historyLoaded = ref(false)
const drawAllGeofencesHistory = ref(false)
const drawAllPoisHistory = ref(false)
const getAllGeofencesHistory = ref([])
const getAllPoisHistory = ref([])
const historyPolyline = ref([])
const tripsOffset = ref(0)
const filteredCount = ref(0)
const assetSensors = ref([])
const requestData = ref({})
const totalDistance = ref(null)
const openPickupDialog = ref(false)

export default function drawHistory() {
  const getTrips = async assetId => {
    toggleLoader(true)
    try {
      let updatedQuery = createSearchQuery().replace(
        /offset=[0-9]+/g,
        `offset=${tripsOffset.value}`
      )
      let { data } = await HistoryService.assetHistoryTrips(
        assetId,
        `${updatedQuery}`
      )
      totalDistance.value = data.totalDistance
      filteredCount.value = data.filteredCount
      if (tripsOffset.value === 0) {
        historyTrips.value = data.items
      } else {
        historyTrips.value = [...historyTrips.value, ...data.items]
      }
      toggleLoader(false)
    } catch (error) {
      toggleLoader(false)
    }
  }
  const updateHistoryTrips = val => {
    historyTrips.value = val
  }
  const setTripsOffset = val => {
    tripsOffset.value = val
  }
  const setHistoryPolyline = val => {
    historyPolyline.value = val
  }
  const getAddressName = async (lat, lng) => {
    try {
      let address = await NominatimService.getAddressName(lat, lng)
      let addressName = `  ${
        address.address.road ? address.address.road : ''
      } ${address.address.city ? address.address.city : ''} ${
        address.address.state ? address.address.state : ''
      } ${address.address.postcode ? address.address.postcode : ''}`
      return addressName
    } catch (error) {
      return {
        address: {
          road: '/'
        }
      }
    }
  }
  const updateRequestData = (assetId, startDate, endDate, sensor) => {
    requestData.value = {
      assetId: assetId,
      fromMoment: startDate,
      toMoment: endDate,
      sensor
    }
  }
  const loadChartData = async () => {
    if (requestData.value.assetId) {
      try {
        toggleLoader(true)
        const response = await HistoryService.getAssetSensors(requestData.value)
        assetSensors.value = response
        toggleLoader(false)
      } catch (error) {
        toggleLoader(false)
      }
    }
  }
  const setHistoryTripPois = data => {
    historyTripPois.value = data.map(poi => {
      return {
        ...poi,
        center: findCentralPoint(poi.location)
      }
    })
  }
  const findCentralPoint = locations => {
    if (locations) {
      let bounds = L.latLngBounds()
      locations
        .map(x => L.latLng(x.latitude, x.longitude))
        .forEach(x => bounds.extend(x))
      return {
        latitude: bounds.getCenter().lat,
        longitude: bounds.getCenter().lng
      }
    }
  }
  const hitoryMapsFitBounds = (map, bounds, polyline) => {
    if (map && bounds) {
      ;(polyline ? polyline : historyPolyline.value)?.forEach(path => {
        bounds.extend({ lat: path.latitude, lng: path.longitude })
      })
    }
    if (historyTripPois.value.length) {
      historyTripPois.value.forEach(poi => {
        poi.location?.forEach(loc => {
          bounds.extend({ lat: loc.latitude, lng: loc.longitude })
        })
      })
    }
    map.fitBounds(bounds)
  }

  const setDrawAllGeofencesHistory = val => {
    drawAllGeofencesHistory.value = val
  }
  const setDrawAllPoisHistory = val => {
    drawAllPoisHistory.value = val
  }
  const setAllGeofencesHistory = val => {
    getAllGeofencesHistory.value = val
  }

  const setAllPoisHistory = val => {
    getAllPoisHistory.value = val
  }
  return {
    historyTrips,
    historyTripPois,
    setHistoryTripPois,
    historyLoaded,
    dateTimeFormats,
    drawAllGeofencesHistory,
    setDrawAllGeofencesHistory,
    drawAllPoisHistory,
    setDrawAllPoisHistory,
    getAllGeofencesHistory,
    setAllGeofencesHistory,
    getAllPoisHistory,
    setAllPoisHistory,
    getAddressName,
    setHistoryPolyline,
    historyPolyline,
    hitoryMapsFitBounds,
    getTrips,
    filteredCount,
    tripsOffset,
    setTripsOffset,
    loadChartData,
    requestData,
    updateRequestData,
    assetSensors,
    updateHistoryTrips,
    totalDistance,
    openPickupDialog
  }
}
