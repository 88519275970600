<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="miniVariant"
    :mobile-breakpoint="0"
    disable-resize-watcher
    mini-variant-width="52"
    width="300"
    height="100vh"
  >
    <v-list dense class="pb-0">
      <v-list-item class="d-flex justify-center">
        <v-img max-height="60" max-width="60" :src="logoImage" />
      </v-list-item>
      <v-divider />

      <v-list-item
        v-if="basicTrackingFeature"
        :to="{ name: 'liveTrackingAssets' }"
      >
        <v-tooltip :disabled="!miniVariant" top z-index="9999">
          <template #activator="{ on }">
            <v-list-item-icon
              :class="[miniVariant ? 'mx-2' : 'mr-2']"
              v-on="on"
            >
              <v-icon> mdi-car-traction-control </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="!miniVariant">{{
                $t('liveTracking.LiveTracking')
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <span>{{ $t('liveTracking.LiveTracking') }}</span>
        </v-tooltip>
      </v-list-item>

      <template
        v-if="
          (!isViewerUser ||
            isLivetrackingUser ||
            isUserAdmin ||
            isSupportUser) &&
          historyIconEnabled
        "
      >
        <v-menu
          v-if="miniVariant"
          z-index="99999"
          offset-x
          transition="scale-transition"
        >
          <template #activator="{ on: menu, attrs }">
            <v-tooltip top z-index="9999">
              <template #activator="{ on: tooltip }">
                <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon>mdi-history</v-icon>
                </v-list-item>
              </template>
              <span>{{ $t('global.History') }}</span>
            </v-tooltip>
          </template>
          <DropdownMenu :menu-items="historyDropdown" />
        </v-menu>
        <v-list-group
          v-else
          v-model="historyMenu"
          @click="closeDropdown('historyMenu')"
        >
          <template #activator>
            <v-list-item-icon class="mr-2">
              <v-icon> mdi-history </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('global.History') }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <DropdownMenu :menu-items="historyDropdown" />
        </v-list-group>
      </template>

      <template
        v-if="
          (!isViewerUser ||
            isLivetrackingUser ||
            isUserAdmin ||
            isSupportUser) &&
          reportsIconEnabled
        "
      >
        <v-menu
          v-if="miniVariant"
          z-index="99999"
          offset-x
          transition="scale-transition"
        >
          <template #activator="{ on: menu, attrs }">
            <v-tooltip top z-index="9999">
              <template #activator="{ on: tooltip }">
                <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon>mdi-text-box</v-icon>
                </v-list-item>
              </template>
              <span>{{ $t('reports.Reports') }}</span>
            </v-tooltip>
          </template>
          <DropdownMenu :menu-items="reportsDropdown" />
        </v-menu>
        <v-list-group
          v-else
          v-model="reportsMenu"
          @click="closeDropdown('reportsMenu')"
        >
          <template #activator>
            <v-list-item-icon class="mr-2">
              <v-icon> mdi-text-box </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('reports.Reports') }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <DropdownMenu :menu-items="reportsDropdown" />
        </v-list-group>
      </template>

      <template
        v-if="
          (!isViewerUser ||
            isLivetrackingUser ||
            isUserAdmin ||
            isSupportUser) &&
          waybillslIconEnabled
        "
      >
        <v-menu
          v-if="miniVariant"
          z-index="99999"
          offset-x
          transition="scale-transition"
        >
          <template #activator="{ on: menu, attrs }">
            <v-tooltip top z-index="9999">
              <template #activator="{ on: tooltip }">
                <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon>mdi-truck-delivery</v-icon>
                </v-list-item>
              </template>
              <span>{{ $t('global.Dispatch') }}</span>
            </v-tooltip>
          </template>
          <DropdownMenu :menu-items="waybillsDropdown" :dispatch="true" />
        </v-menu>
        <v-list-group
          v-else
          v-model="waybillsMenu"
          @click="closeDropdown('waybillsMenu')"
        >
          <template #activator>
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-truck-delivery</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('global.Dispatch') }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <DropdownMenu
            :menu-items="waybillsDropdown"
            :dispatch-mini-variant="true"
          />
        </v-list-group>
      </template>
      <template
        v-if="
          (!isViewerUser && !isLivetrackingUser) || isUserAdmin || isSupportUser
        "
      >
        <v-list-item :to="{ name: 'Geofences', query: routeQuery }">
          <v-tooltip :disabled="!miniVariant" top z-index="9999">
            <template #activator="{ on }">
              <v-list-item-icon
                :class="[miniVariant ? 'mx-2' : 'mr-2']"
                v-on="on"
              >
                <v-icon> pentagon </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="!miniVariant">{{
                  $t('geofences.Geofences')
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>{{ $t('geofences.Geofences') }}</span>
          </v-tooltip>
        </v-list-item>
      </template>

      <template
        v-if="
          (!isViewerUser && !isLivetrackingUser) || isUserAdmin || isSupportUser
        "
      >
        <v-list-item :to="{ name: 'Documents', query: routeQuery }">
          <v-tooltip :disabled="!miniVariant" top z-index="9999">
            <template #activator="{ on }">
              <v-list-item-icon
                :class="[miniVariant ? 'mx-2' : 'mr-2']"
                v-on="on"
              >
                <v-icon> description </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="!miniVariant">{{
                  $t('documents.Documents')
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>{{ $t('documents.Documents') }}</span>
          </v-tooltip>
        </v-list-item>
      </template>

      <template
        v-if="
          ((!isViewerUser && !isLivetrackingUser) ||
            isUserAdmin ||
            isSupportUser) &&
          administrationIconEnabled
        "
      >
        <v-menu
          v-if="miniVariant"
          z-index="99999"
          offset-x
          transition="scale-transition"
        >
          <template #activator="{ on: menu, attrs }">
            <v-tooltip top z-index="9999">
              <template #activator="{ on: tooltip }">
                <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon>mdi-account-multiple</v-icon>
                </v-list-item>
              </template>
              <span>{{ $t('administration.Administration') }}</span>
            </v-tooltip>
          </template>
          <DropdownMenu :menu-items="administrationDropdown" />
        </v-menu>
        <v-list-group
          v-else
          v-model="administrationMenu"
          @click="closeDropdown('administrationMenu')"
        >
          <template #activator>
            <v-list-item-icon class="mr-2">
              <v-icon> mdi-account-multiple </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t('administration.Administration')
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <DropdownMenu :menu-items="administrationDropdown" />
        </v-list-group>
      </template>

      <template v-if="getEldValue">
        <v-menu
          v-if="miniVariant"
          z-index="99999"
          offset-x
          transition="scale-transition"
        >
          <template #activator="{ on: menu, attrs }">
            <v-tooltip top z-index="9999">
              <template #activator="{ on: tooltip }">
                <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon>badge</v-icon>
                </v-list-item>
              </template>
              <span>{{ $t('hos.compliance') }}</span>
            </v-tooltip>
          </template>
          <DropdownMenu :menu-items="hosDropdown" />
        </v-menu>
        <v-list-group
          v-else
          v-model="hosMenu"
          @click="closeDropdown('hosMenu')"
        >
          <template #activator>
            <v-list-item-icon class="mr-2">
              <v-icon> badge </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('hos.compliance') }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <DropdownMenu :menu-items="hosDropdown" />
        </v-list-group>
      </template>
    </v-list>

    <template #append>
      <v-divider />
      <v-list dense>
        <template
          v-if="isSupportUser && !supportingUser && supportPanelIconEnabled"
        >
          <v-menu
            v-if="miniVariant"
            z-index="99999"
            offset-x
            transition="scale-transition"
          >
            <template #activator="{ on: menu, attrs }">
              <v-tooltip top z-index="9999">
                <template #activator="{ on: tooltip }">
                  <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-icon>mdi-face-agent</v-icon>
                  </v-list-item>
                </template>
                <span>{{ $t('supportPannel.SupportPannel') }}</span>
              </v-tooltip>
            </template>
            <DropdownMenu
              :menu-items="supportPannelDropdown"
              :support-pannel="true"
            />
          </v-menu>
          <v-list-group
            v-else
            v-model="supportMenu"
            @click="closeDropdown('supportMenu')"
          >
            <template #activator>
              <v-list-item-icon class="mr-2">
                <v-icon> mdi-face-agent </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t('supportPannel.SupportPannel')
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <DropdownMenu
              :menu-items="supportPannelDropdown"
              :support-pannel="true"
            />
          </v-list-group>
        </template>

        <template
          v-if="
            (!isViewerUser && !isLivetrackingUser) ||
            isUserAdmin ||
            isSupportUser
          "
        >
          <v-menu
            v-if="miniVariant"
            z-index="99999"
            offset-x
            transition="scale-transition"
          >
            <template #activator="{ on: menu, attrs }">
              <v-tooltip top z-index="9999">
                <template #activator="{ on: tooltip }">
                  <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-icon>mdi-cog</v-icon>
                  </v-list-item>
                </template>
                <span>{{ $t('global.Settings') }}</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item
                class="px-10 font-weight-medium text-caption"
                :to="{ name: 'CompanySettings' }"
              >
                {{ $t('companySettings.General') }}
              </v-list-item>
              <v-list-item
                class="px-10 font-weight-medium text-caption"
                :to="{ name: 'BasicTracking' }"
              >
                {{ $t('companySettings.Features') }}
              </v-list-item>
              <v-list-item
                class="px-10 font-weight-medium text-caption"
                :to="{ name: 'Integrations' }"
              >
                Integrations
              </v-list-item>
            </v-list>
          </v-menu>
          <v-list-group
            v-else
            v-model="settingsMenu"
            @click="closeDropdown('settingsMenu')"
          >
            <template #activator>
              <v-list-item-icon class="mr-2">
                <v-icon> mdi-cog </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('global.Settings') }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list>
              <v-list-item
                class="px-10 font-weight-medium text-caption"
                :to="{ name: 'CompanySettings' }"
              >
                {{ $t('companySettings.General') }}
              </v-list-item>
              <v-list-item
                class="px-10 font-weight-medium text-caption"
                :to="{ name: 'BasicTracking' }"
              >
                {{ $t('companySettings.Features') }}
              </v-list-item>
              <v-list-item
                class="px-10 font-weight-medium text-caption"
                :to="{ name: 'Integrations' }"
              >
                Integrations
              </v-list-item>
            </v-list>
          </v-list-group>
        </template>
        <v-list-item v-if="unReviewedAlerts > 0" @click="alertsRoute">
          <v-badge overlap color="red" :content="unReviewedAlerts">
            <v-list-item-icon
              :class="[miniVariant ? 'mx-0' : 'mr-0']"
              class="ml-0"
            >
              <v-icon> mdi-bell </v-icon>
            </v-list-item-icon>
          </v-badge>
          <v-list-item-content class="ml-2">
            <v-list-item-title v-if="!miniVariant">Alerts</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else @click="alertsRoute">
          <v-tooltip :disabled="!miniVariant" top z-index="9999">
            <template #activator="{ on }">
              <v-list-item-icon
                v-if="unReviewedAlerts === 0"
                :class="[miniVariant ? 'mx-2' : 'mr-2']"
                v-on="on"
              >
                <v-icon> mdi-bell </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="!miniVariant"
                  >Alerts</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <span>Alerts</span>
          </v-tooltip>
        </v-list-item>
        <v-menu
          v-if="miniVariant"
          z-index="99999"
          top
          offset-x
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template #activator="{ on: menu, attrs }">
            <v-tooltip top z-index="9999">
              <template #activator="{ on: tooltip }">
                <v-list-item-title
                  class="d-flex justify-center"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-avatar
                    class="my-2"
                    :class="[
                      supportingUser ? 'red lighten-1 white--text' : 'accent'
                    ]"
                    size="24"
                  >
                    <span class="font-weight-regular text-caption">{{
                      username.slice(0, 2)
                    }}</span>
                  </v-avatar>
                </v-list-item-title>
              </template>
              <div class="d-flex flex-column">
                <span>{{ username }}</span>
                <span
                  v-if="!supportingUser"
                  style="
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  >({{ companyName }})</span
                >
                <div
                  v-if="supportingUser"
                  class="d-flex"
                  style="font-size: 11px"
                >
                  {{ supportingUser.userName }}
                  |
                  <div v-for="(role, i) in supportingUser.userRoles" :key="i">
                    <div>{{ role }}</div>
                  </div>
                </div>
              </div>
            </v-tooltip>
          </template>
          <DropdownMenu :translations="true" :mini-variant="miniVariant" />
        </v-menu>

        <v-list-group
          v-else
          v-model="userMenu"
          @click="closeDropdown('userMenu')"
        >
          <template #activator>
            <v-list-item class="px-0" style="width: 70%">
              <v-avatar
                class="mr-2"
                :class="[
                  supportingUser ? 'red lighten-1 white--text' : 'accent'
                ]"
                size="24"
              >
                <span class="font-weight-regular text-caption">{{
                  username.slice(0, 2)
                }}</span>
              </v-avatar>
              <v-list-item-title
                class="d-flex flex-column"
                :class="{
                  supportingUser: 'red--text px-3 py-2 '
                }"
              >
                <span>{{ username }}</span>
                <span
                  v-if="!supportingUser"
                  style="
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  >({{ companyName }})</span
                >

                <div v-if="supportingUser" class="d-flex">
                  {{ supportingUser.userName }}
                  |
                  <div
                    v-for="(role, i) in supportingUser.userRoles"
                    :key="i"
                    style="font-size: 11px"
                  >
                    <div>{{ role }}</div>
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item>
          </template>
          <DropdownMenu :translations="true" />
        </v-list-group>
        <v-list-item @click="$router.push({ name: 'HelpComponent' })">
          <v-tooltip :disabled="!miniVariant" top z-index="9999">
            <template #activator="{ on }">
              <v-list-item-icon
                :class="[miniVariant ? 'mx-2' : 'mr-2']"
                v-on="on"
              >
                <v-icon>help_outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="!miniVariant">{{
                  $t('global.Help')
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>{{ $t('global.Help') }}</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item class="px-0">
          <v-btn
            class="px-0 d-flex justify-end"
            block
            text
            @click.stop="toggleSidebar"
          >
            <v-icon v-if="miniVariant" class="mr-3">mdi-chevron-right</v-icon>
            <v-icon v-if="!miniVariant" class="mr-3">mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>
<script>
import menuDropdowns from './menuDropdowns.js'
import DropdownMenu from './DropdownMenu.vue'
import supportingUserData from '@/components/SupportPannel/use/supportingUser'
import updateSwitchedCompanyData from '@/components/SupportPannel/use/switchCompanies'
import tablePaging from '@/components/tablePaging'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
import startAndEndDateTime from '@/components/use/startAndEndDateTime'
import useAssets from '@/components/Livetracking/use/useAssets'
export default {
  components: {
    DropdownMenu
  },
  setup() {
    const {
      getCompanySettings,
      getUsername,
      getUserRoles,
      getSupportTokenDetails,
      getCompanyFeatures,
      getChildCompanyFeatures,
      getChildCompanySettings
    } = getCompanySettingsFromToken()
    const {
      administrationDropdown,
      reportsDropdown,
      historyDropdown,
      supportPannelDropdown,
      waybillsDropdown,
      hosDropdown
    } = menuDropdowns()
    const { routeQuery } = tablePaging()
    const { supportingUser, updateSupportingUsernameAndRoles } =
      supportingUserData()
    const { updateSwitchedCompanyName, companyName } =
      updateSwitchedCompanyData()
    const { initialDateRange } = startAndEndDateTime()
    const {
      tableOptions,
      createSearchQuery,
      updateTableOptions,
      updateServerItemsLength,
      serverItemsLength,
      updateItemsPerPage,
      updatePage
    } = tablePaging()
    const { unReviewedAlerts } = useAssets()
    return {
      initialDateRange,
      administrationDropdown,
      reportsDropdown,
      historyDropdown,
      supportPannelDropdown,
      waybillsDropdown,
      supportingUser,
      updateSupportingUsernameAndRoles,
      updateSwitchedCompanyName,
      companyName,
      routeQuery,
      getCompanySettings,
      getUsername,
      getUserRoles,
      getSupportTokenDetails,
      getCompanyFeatures,
      getChildCompanyFeatures,
      hosDropdown,
      getChildCompanySettings,
      tableOptions,
      createSearchQuery,
      updateTableOptions,
      updateServerItemsLength,
      serverItemsLength,
      updateItemsPerPage,
      updatePage,
      unReviewedAlerts
    }
  },
  data() {
    return {
      drawer: true,
      miniVariant: false,
      userRole: '',
      companyId: '',
      username: '',
      userMenu: false,
      administrationMenu: false,
      supportMenu: true,
      waybillsMenu: false,
      reportsMenu: false,
      historyMenu: false,
      livetrackingMenu: false,
      settingsMenu: false,
      hosMenu: false
    }
  },
  computed: {
    logoImage() {
      return require(process.env.VUE_APP_LOGO_URL)
    },
    isViewerUser() {
      return this.userRole?.includes('Viewer')
    },
    isLivetrackingUser() {
      return this.userRole?.includes('Livetracking')
    },
    isUserAdmin() {
      return this.userRole?.includes('Admin')
    },
    isSupportUser() {
      return (
        this.userRole?.includes('Support') ||
        this.userRole?.includes('Reseller')
      )
    },
    basicTrackingFeature() {
      return this.getChildCompanyFeatures?.basicTracking
        ? this.getChildCompanyFeatures?.basicTracking
        : this.getCompanyFeatures?.basicTracking
    },
    reportsIconEnabled() {
      return this.reportsDropdown.some(item => item.enabled)
    },
    historyIconEnabled() {
      return this.historyDropdown.some(item => item.enabled)
    },
    administrationIconEnabled() {
      return this.administrationDropdown.some(item => item.enabled)
    },
    supportPanelIconEnabled() {
      return this.supportPannelDropdown.some(item => item.enabled)
    },
    waybillslIconEnabled() {
      return this.waybillsDropdown.some(item => item.enabled)
    },
    getEldValue() {
      if (this.getChildCompanyFeatures?.hosSettings.eld) {
        return this.getChildCompanyFeatures.hosSettings.eld
      } else if (this.getCompanyFeatures?.hosSettings.eld) {
        return this.getCompanyFeatures.hosSettings.eld
      } else {
        return false
      }
    }
  },
  async created() {
    this.userRole = this.getUserRoles || []
    this.companyId = this.getCompanySettings?.companyId
    this.username = this.getUsername || ''
    if (this.getSupportTokenDetails) {
      this.updateSupportingUsernameAndRoles(this.getSupportTokenDetails)
    }
    this.updateSwitchedCompanyName()
  },
  methods: {
    toggleSidebar() {
      this.miniVariant = !this.miniVariant
    },
    alertsRoute() {
      this.$router.push({
        name: 'Alerts',
        query: {
          ...this.routeQuery,
          companyId: this.getChildCompanySettings
            ? this.getChildCompanySettings.companyId
            : this.getCompanySettings.companyId,
          sortOrder: 'Descending'
        }
      })
    },
    closeDropdown(val) {
      if (val !== 'historyMenu' && this.historyMenu) {
        this.historyMenu = false
      }
      if (val !== 'reportsMenu' && this.reportsMenu) {
        this.reportsMenu = false
      }
      if (val !== 'administrationMenu' && this.administrationMenu) {
        this.administrationMenu = false
      }
      if (val !== 'waybillsMenu' && this.waybillsMenu) {
        this.waybillsMenu = false
      }
      if (val !== 'userMenu' && this.userMenu) {
        this.userMenu = false
      }
      if (val !== 'supportMenu' && this.supportMenu) {
        this.supportMenu = false
      }
      if (val !== 'settingsMenu' && this.settingsMenu) {
        this.settingsMenu = false
      }
      if (val !== 'hosMenu' && this.hosMenu) {
        this.hosMenu = false
      }
    }
  }
}
</script>
