import { i18n } from '@/translations/i18n'
import {
  assetStateValue,
  lastUpdateOlderThan6h
} from '@/components/Livetracking/helpers/assetsMethods'
import imagePathTruck from '@/images/icons/trailer-list.png'
import imagePathContainer from '@/images/icons/container.png'
export const toolTip = asset => {
  let condition =
    asset.type !== 45 &&
    asset.type !== 46 &&
    asset.type !== 4 &&
    asset.type !== 5

  return `<div class="pt-3 pl-3 pr-3 mt-3">
            <div class="d-flex justify-center">
              <span class="font-weight-bold">${asset.name}</span>
            </div>
            <div class="d-flex justify-center my-1">
            ${condition ? asset.plateNumber : ''}
            </div>
           </div>
            ${condition ? toolTipVehicles(asset) : ''}
          </div>`
}
const toolTipVehicles = asset => {
  return `
    ${
  asset.driverName
    ? `<div class="pa-3 d-flex justify-center">${driverName(asset)}</div>`
    : ''
}
    ${
  asset.assetHaulingInformation.length > 0
    ? `<div class="pa-3 d-flex justify-center">${haulingInfo(asset)}</div>`
    : ''
}
    <div class="d-flex justify-center align-center px-3 pb-3">
      <img width="25" class="mr-3" src="${assetStatusImg(asset)}"/>  
      <span class="font-weight-black mr-2">
        <span class="font-weight-light">${assetStateValue(asset)}</span>
      </span>
    </div>`
}
const driverName = asset => {
  if (asset.driverName) {
    return `<span class="mdi mdi-account mr-1"></span>
        ${asset.driverName}`
  } else {
    return ''
  }
}
const haulingInfo = asset => {
  if (asset.assetHaulingInformation.length > 0) {
    return `
      <span class="mb-1">
        <img src="${
  asset.assetHaulingInformation[0].hauledDeviceUid
    ? imagePathContainer
    : imagePathTruck
}" alt="Truck Icon" width="20" height="20" class="mr-2" />
      </span>
      ${
  asset.assetHaulingInformation[0].hauledDeviceUid
    ? asset.assetHaulingInformation[0].hauledDeviceUid
    : asset.assetHaulingInformation[0].assetName
}`
  } else {
    return ''
  }
}
export const popUp = assetsInCluster => {
  let assetsStates = assetState(assetsInCluster)
  return `<div class="v-list pt-0 v-sheet theme--light ml-1">
            <div class="v-list-item theme--light text-subtitle-2">
              ${assetsInCluster.length} ${i18n.t('global.Assets')}
            </div>
            <div class="v-list-item theme--light">
              <img width="25" class="mr-3" src="${require('@/images/icons/idle-status.svg')}" />
              <span class="font-weight-black mr-2">  
              ${assetsStates.offline} </span>
              <span class="font-weight-light"> 
              ${i18n.t('global.Offline')} </span>
            </div>
            <div class="v-list-item theme--light">
              <img width="25" class="mr-3" src="${require('@/images/icons/parking-status.svg')}" />
              <span class="font-weight-black mr-2">  
              ${assetsStates.off} </span>
              <span class="font-weight-light"> 
              ${i18n.t('assetsFilters.Parking')} </span>
            </div>
            <div class="v-list-item theme--light">
              <img width="25" class="mr-3" src="${require('@/images/icons/driving-status.svg')}" />
              <span class="font-weight-black mr-2">
              ${assetsStates.driving}</span>
              <span class="font-weight-light"> 
              ${i18n.t('global.Driving')} </span>
            </div>
            <div class="v-list-item theme--light">
              <img width="25" class="mr-3" src="${require('@/images/icons/idle-status.svg')}" />
              <span class="font-weight-black mr-2"> 
              ${assetsStates.idling}</span>
              <span class="font-weight-light"> ${i18n.t('global.Idle')} </span>
            </div>
          </div>`
}
const assetStatusImg = asset => {
  if (asset.state === 1) {
    return require('@/images/icons/driving-status.svg')
  } else if (asset.state === 3) {
    return require('@/images/icons/parking-status.svg')
  } else {
    return require('@/images/icons/idle-status.svg')
  }
}
const assetState = assets => {
  return assets.reduce(
    (acc, asset) => {
      if (
        asset.options?.currentState?.type === 4 ||
        asset.currentState.type === 4 ||
        asset.options?.currentState?.type === 5 ||
        asset.currentState.type === 5 ||
        asset.options?.currentState?.type === 45 ||
        asset.currentState.type === 45 ||
        asset.options?.currentState?.type === 46 ||
        asset.currentState.type === 46
      ) {
        acc.offline++
      } else {
        if (asset.options) {
          if (
            asset.options.currentState.state === 0 ||
            lastUpdateOlderThan6h(asset.options.currentState)
          ) {
            acc.offline++
          } else if (asset.options.currentState.state === 1) {
            acc.driving++
          } else if (asset.options.currentState.state === 3) {
            acc.off++
          } else if (asset.options.currentState.state === 2) {
            acc.idling++
          }
        } else {
          if (
            asset.currentState.state === 0 ||
            lastUpdateOlderThan6h(asset.currentState)
          ) {
            acc.offline++
          } else if (asset.currentState.state === 1) {
            acc.driving++
          } else if (asset.currentState.state === 3) {
            acc.off++
          } else if (asset.currentState.state === 2) {
            acc.idling++
          }
        }
      }
      return acc
    },
    {
      offline: 0,
      driving: 0,
      idling: 0,
      off: 0
    }
  )
}
